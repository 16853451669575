import { useEffect, useState } from 'react';
import {
    Box,
    Center,
    Divider,
    Fade,
    Flex,
    Grid,
    Image,
    Text,
} from '@chakra-ui/react';

// Styles
import './styles.scss';

const Paint = ({ paint, handleClick }) => {

    return (
        <>
            <Fade in={true}>
                <Grid className="painting">
                    <Fade in={true}>
                        <Image className="paintingImage" src={paint.image} onClick={() => handleClick(paint)} />
                    </Fade>
                    <Text className="maintitle">{paint.title}</Text>
                    <Center>
                        <Divider width={'80%'} marginTop={'3px'} color={'#dadddd'} />
                    </Center>
                    <Flex width={'100%'}>
                        <Grid className="information">
                            <Text className="datasheet">Ficha técnica</Text>
                            <Text className="title">
                                Año:{' '}
                                <Box className="text" as="span">
                                    {paint.year}
                                </Box>
                            </Text>
                            <Text className="title">
                                Materiales:{' '}
                                <Box className="text" as="span">
                                    {paint.materials}
                                </Box>
                            </Text>
                            <Text className="title">
                                Tamaño:{' '}
                                <Box className="text" as="span">
                                    {paint.size}
                                </Box>
                            </Text>
                        </Grid>
                    </Flex>
                </Grid>
            </Fade>
        </>
    );
};

export default Paint;
