import { useState } from 'react';
import {
    Box,
    Button,
    Textarea,
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Center,
    Spinner,
    Grid,
    ScaleFade,
    Text,
    Flex,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

// Services
import { sendEmail } from '../../services/mailer/mail-service';
import { ORDER_TEMPLATE } from '../../services/mailer/mail-templates';
import { validateOrder } from '../../utils/form-helper';

// Styles
import './style.scss';

const OrderForm = ({ item, type, preorder }) => {
    // TODO: just must be one message per item

    const [data, setData] = useState({
        fullname: '',
        email: '',
        phone: '',
        address: '',
        quantity: 1,
        comments: '',
    });

    const [errors, setErrors] = useState({
        fullname: false,
        email: false,
        phone: false,
        address: false,
        quantity: false,
    });

    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!sent) {
            if (validateOrder(data, errors, setErrors)) {
                setLoading(true);

                // Bind data and message
                const formatData = {
                    data : data,
                    message : getMessage(),
                }

                sendEmail(formatData, setLoading, ORDER_TEMPLATE);
                setSent(true);
            }
        } else {
            navigate('/inicio');
        }
    };

    const getMessage = () => {

        switch (type) {
            case 'book':
                return `
                Libro: ${item.title}
                Nombre Completo: ${data.fullname}
                Correo Electrónico: ${data.email}
                Teléfono: ${data.phone}
                Dirección: ${data.address}
                Cantidad de Libros: ${data.quantity}
                Comentarios Adicionales: ${data.comments}
            `;
            case 'paint':
                return `
                Pintura: ${item.title} - ${item.year}
                Nombre Completo: ${data.fullname}
                Correo Electrónico: ${data.email}
                Teléfono: ${data.phone}
                Dirección: ${data.address}
                Comentarios Adicionales: ${data.comments}
            `;
        }
    }

    const handleChange = (field, value) => {
        setData({ ...data, [field]: value });
        setErrors({ ...errors, [field]: false });
    };

    return (
        <div className="orderform">
            {!loading && !sent &&
                <>
                    <FormControl className='orderform-item' isRequired isInvalid={errors.fullname}>
                        <Flex gap={4}>
                            <Box pt={2}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
                                </svg>
                            </Box>
                            <Input
                                placeholder='Nombre completo'
                                className="input"
                                value={data.fullname}
                                name="fullname"
                                type="text"
                                onChange={(e) => handleChange('fullname', e.target.value)}
                                _placeholder={{ color: '#303d4d', fontSize: '14px' }}
                            />
                        </Flex>
                        {errors.fullname && <FormErrorMessage>El nombre es inválido</FormErrorMessage>}
                    </FormControl>

                    <FormControl className='orderform-item' isRequired isInvalid={errors.email}>
                        <Flex gap={4}>
                            <Box pt={2}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-envelope-at" viewBox="0 0 16 16">
                                    <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                                    <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                                </svg>
                            </Box>
                            <Input
                                className="input"
                                placeholder='Email'
                                _placeholder={{ color: '#303d4d', fontSize: '14px' }}
                                value={data.email}
                                name="email"
                                type="email"
                                onChange={(e) => handleChange('email', e.target.value)}
                            />
                        </Flex>
                        {errors.email && <FormErrorMessage>El email es inválido</FormErrorMessage>}
                    </FormControl>

                    <FormControl className='orderform-item' isRequired isInvalid={errors.phone}>
                        <Flex gap={4}>
                            <Box pt={2}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                </svg>
                            </Box>
                            <Input
                                placeholder='Número de Teléfono'
                                className="input"
                                value={data.phone}
                                name="phone"
                                type="tel"
                                onChange={(e) => handleChange('phone', e.target.value)}
                                _placeholder={{ color: '#303d4d', fontSize: '14px' }}
                            />
                        </Flex>
                        {errors.phone && <FormErrorMessage>El número de teléfono es inválido</FormErrorMessage>}
                    </FormControl>

                    <FormControl className='orderform-item' isRequired isInvalid={errors.address}>
                        <Flex gap={4}>
                            <Box pt={2}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-house-add-fill" viewBox="0 0 16 16">
                                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 1 1-1 0v-1h-1a.5.5 0 1 1 0-1h1v-1a.5.5 0 0 1 1 0" />
                                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L8 2.207l6.646 6.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293z" />
                                    <path d="m8 3.293 4.712 4.712A4.5 4.5 0 0 0 8.758 15H3.5A1.5 1.5 0 0 1 2 13.5V9.293z" />
                                </svg>
                            </Box>
                            <Input
                                placeholder='Dirección de Envío'
                                className="input"
                                value={data.address}
                                name="address"
                                type="text"
                                onChange={(e) => handleChange('address', e.target.value)}
                                _placeholder={{ color: '#303d4d', fontSize: '14px' }}
                            />
                        </Flex>
                        {errors.address && <FormErrorMessage>La dirección de envío es inválida</FormErrorMessage>}
                    </FormControl>

                    {type === 'book' &&
                        <FormControl className='orderform-item' isRequired>
                            <Flex>
                                <FormLabel pt={2} fontSize={'14px'} fontWeight={'bold'} htmlFor="quantity">Cantidad</FormLabel>
                                <NumberInput defaultValue={1} min={1} max={5}>
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </Flex>
                        </FormControl>
                    }

                    <FormControl className='orderform-item'>
                        <Textarea
                            className="textarea"
                            name="message"
                            maxHeight={'150px'}
                            onChange={(e) => handleChange('comments', e.target.value)}
                            placeholder='Comentarios'
                            _placeholder={{ color: '#303d4d', fontSize: '14px', padding: '2px' }}
                        />
                    </FormControl>
                </>}
            {loading &&
                <Center>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='#724162'
                        size='xl'
                        marginY={'163px'}
                    />
                </Center>
            }
            {!loading &&
                sent &&
                <ScaleFade initialScale={0.9} in={sent}>
                    <Grid className='contactform-sent' gap={5} paddingY={'35px'}>
                        <Text fontSize={'lg'} textAlign={'center'} as={'b'}>{preorder ? '¡Reserva realizada!' : 'Solicitud realizada!'}</Text>
                        <Text fontSize={'14px'} textAlign={'center'}>
                            Gracias por ponerte en contacto. Tu mensaje ha sido recibido y nos pondremos en contacto contigo tan pronto como sea posible. ¡Saludos!
                        </Text>
                    </Grid>
                </ScaleFade>
            }
            {!preorder ? <Button
                className="button"
                variant="primary"
                type="submit"
                onClick={(e) => handleSubmit(e)}
                disabled={loading}
            >
                {loading ?
                    'Solicitando...'
                    :
                    sent ?
                        <ScaleFade initialScale={0.9} in={sent}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                            </svg>
                        </ScaleFade>
                        :
                        'SOLICITAR'}
            </Button>
                :
                <Button
                    className="presalebutton"
                    onClick={(e) => handleSubmit(e)}
                    disabled={loading}
                >
                    {loading ?
                        'Reservando...'
                        :
                        sent ?
                            <ScaleFade initialScale={0.9} in={sent}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
                                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                                </svg>
                            </ScaleFade>
                            :
                            'RESERVA AHORA'}
                </Button>

            }
            <Text className={'recaptcha'}>
                This site is protected by reCAPTCHA and the Google&nbsp;
                <a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp; and&nbsp;
                <a href="https://policies.google.com/terms">Terms of Service</a>&nbsp; apply.
            </Text>
        </div>
    );
};

export default OrderForm;
