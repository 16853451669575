import ensayoRacionalidadCover from '../resources/books/ensayo_sobre_la_racionalidad.png';
import ensayoRacionalidadSpine from '../resources/books/ensayo_sobre_la_racionalidad_dorso.png';
import escuelaQueEnfermaCover from '../resources/books/la_escuela_que_enferma.png';
import escuelaQueEnfermaSpine from '../resources/books/la_escuela_que_enferma_dorso.png';
import laEscuelaQueEstafaCover from '../resources/books/la_escuela_que_estafa.png';
import laEscuelaQueEstafaSpine from '../resources/books/la_escuela_que_estafa_dorso.png';
import manualDelCorruptoCover from '../resources/books/manual_del_corrupto_argentino.png';
import manualDelCorruptoSpine from '../resources/books/manual_del_corrupto_argentino_dorso.png';
import meAmalabasCover from '../resources/books/me_amalabas.png';
import meAmalabasSpine from '../resources/books/me_amalabas_dorso.png';
import veinteCosasCover from '../resources/books/veinte_cosas.png';
import veinteCosasSpine from '../resources/books/veinte_cosas_dorso.png';
import veinteCosasPhone from '../resources/ebooks/phone-20cosas.png'
import veinteCosasIpad from '../resources/ebooks/ipad-20cosas.png'

const books = [
    {
        id: 1,
        amazonlink: '',
        author: 'Silvia H Prost',
        available: true,
        date: 'Marzo 2024',
        description: 'Cuando el regreso a la democracia de los años 80, la educación ya estaba en problemas. La reforma de la Ley Federal menemista y la posterior derogación y cambio por la Ley Nacional de 2006 contribuyeron a una crisis en proceso que la pandemia puso al descubierto. En los últimos años hicieron su aparición nuevos males como la hiper fragmentación y descomposición del sistema, la proliferación del semianalfabetismo, el adoctrinamiento, el vaciamiento de contenidos y la estafa. El texto analiza los hechos que según la autora han dado como resultado el desastre educativo que padecemos.   Para ello recurre a datos de la realidad, fuentes de diverso tipo, aportes de especialistas y anécdotas de su paso por las aulas. Se trata de un análisis exhaustivo y realista, con un tono esperanzador y convocante, en pos de reconstruir la educación pública argentina y reubicarla en el lugar privilegiado que alguna vez tuvo.',
        ebook: false,
        ebookPrice: '?',
        edition: '1er edición',
        editorial: 'De los cuatro vientos',
        format: 'Disponible en E-book y papel',
        image: laEscuelaQueEstafaCover,
        isbn: '',
        link: 'la-escuela-que-estafa',
        new: true,
        pages: null,
        presale: false,
        price: '11.400,00',
        pseudonimo: false,
        soldBy: [
            {
              title: 'Cúspide',
              url: 'https://cuspide.com/producto/la-escuela-que-estafa/'
            }
          ],
        spine: laEscuelaQueEstafaSpine,
        subtitle: 'Hitos de la debacle educativa argentina',
        title: 'La escuela que estafa',
        year: '2024',
    },
    {
        id: 2,
        amazonlink:
            'https://www.amazon.com/dppui/pay-select?ie=UTF8&ASIN.0=B0BL8YX4HR&isPaymentInstrumentSelected=0&isPreorder=0&message=Customer+hasn%27t+declared+residence+address&result=NEED_DECLARED_ADDRESS&asin=B0BL8YX4HR&displayedPrice=6.99&displayedPriceCurrency=USD&displayedPriceCurrencyCode=USD&site=RetailWebsite&_token=g01tc%2BKPXw5fO8OUAcUGU7LArssL0h9lmyd74CqvM1IzAAAADAAAAABlmbjtcmF3AAAAABVX8CwXqz4nuL9RKX%2F%2F%2Fw%3D%3D&preferenceType=DefaultOneClick&clientId=dbs&t=fiona&isSeriesPurchase=0&device.encryptedDeviceAccountId=DT%3AA2CTZ977SKFQZY&transactionContext=one-click&destinationPath=%2Fapi%2Fbifrost%2Facquisitions%2Fv1%2Factions%2Farn%3Aaction%3Apa%3A1%3AQjBCTDhZWDRIUjpBVFZQREtJS1gwREVSOkJ1eTpudWxsOjYuOTk6VVNEOm51bGw%3D%3Fcsrf%3Dg5xR6Vi6Q8kBvgczNSBXWWSGB9dObrlocqYX62f%252FvYR4AAAAAQAAAABlmbjmcmF3AAAAAKs%252BFBXVfD4nuL9rqj%252BgAw%253D%253D%26x-client-id%3Debook-dp&bifrost_enabled=1&payment.mode=one-click&items%5B0%5D.audibleNarration.audibleAsin=&x-client-id=ebook-dp&ref_=dbs_p_ebk_r00_pbcb_cv1c00&csrf=g5xR6Vi6Q8kBvgczNSBXWWSGB9dObrlocqYX62f%2FvYR4AAAAAQAAAABlmbjmcmF3AAAAAKs%2BFBXVfD4nuL9rqj%2BgAw%3D%3D&items%5B0%5D.audibleNarration.addNarration=0&items%5B0%5D.audibleNarration.audibleOurPrice=&submit.one-click-order.x=Comprar+en+1-Clic',
        author: 'Silvia H Prost',
        available: true,
        date: '26 Diciembre 2021',
        description:
            'Las poesías publicadas en este libro han sido escritas y reescritas en distintas épocas de la vida de la autora, sin embargo, las une su tenor dramático y angustiante, por lo cual son expuestas en este momento –sin dudas difícil y triste– de nuestro país. El final del libro remata con tres poemas escritos en glíglico cortazariano, dando el último el título a la obra, como si fuese necesario recordar que, en definitiva, siempre el amor es lo que nos salva.',
        ebook: true,
        ebookPrice: '6,99',
        edition: '1er edición',
        editorial: 'Editorial Autores de Argentina',
        format: 'Disponible en E-book y papel',
        image: meAmalabasCover,
        isbn: '9789878731582',
        link: 'me-amalabas',
        new: true,
        pages: 46,
        presale: false,
        price: '7.618,00',
        pseudonimo: false,
        soldBy: [],
        spine: meAmalabasSpine,
        subtitle: 'Cuatrocientos versos de angustia y tres glíglicos de amor',
        title: 'Me amalabas',
        year: '2022',
    },
    {
        id: 3,
        amazonlink:
            'https://www.amazon.com/dppui/pay-select?ie=UTF8&ASIN.0=B0771GMCVP&isPaymentInstrumentSelected=0&isPreorder=0&message=Customer+hasn%27t+declared+residence+address&result=NEED_DECLARED_ADDRESS&asin=B0771GMCVP&displayedPrice=5.45&displayedPriceCurrency=USD&displayedPriceCurrencyCode=USD&site=RetailWebsite&_token=g30FIr2EWLhxGprD1J9KMNmT2Uo8ytQw%2Bo7XJaC4hJNkAAAADAAAAABlmblTcmF3AAAAABVX8CwXqz4nuL9RKX%2F%2F%2Fw%3D%3D&preferenceType=DefaultOneClick&clientId=dbs&t=fiona&isSeriesPurchase=0&device.encryptedDeviceAccountId=DT%3AA2CTZ977SKFQZY&transactionContext=one-click&destinationPath=%2Fapi%2Fbifrost%2Facquisitions%2Fv1%2Factions%2Farn%3Aaction%3Apa%3A1%3AQjA3NzFHTUNWUDpBVFZQREtJS1gwREVSOkJ1eTpudWxsOjUuNDU6VVNEOm51bGw%3D%3Fcsrf%3Dg8h4t%252BhvApeNJFJ1gz34w%252FBytW8RknjJA%252BJlBTFaVl%252BdAAAAAQAAAABlmblHcmF3AAAAAKs%252BFBXVfD4nuL9rqj%252BgAw%253D%253D%26x-client-id%3Debook-dp&bifrost_enabled=1&payment.mode=one-click&items%5B0%5D.audibleNarration.audibleAsin=&x-client-id=ebook-dp&ref_=dbs_p_ebk_r00_pbcb_cv1c00&csrf=g8h4t%2BhvApeNJFJ1gz34w%2FBytW8RknjJA%2BJlBTFaVl%2BdAAAAAQAAAABlmblHcmF3AAAAAKs%2BFBXVfD4nuL9rqj%2BgAw%3D%3D&items%5B0%5D.audibleNarration.addNarration=0&items%5B0%5D.audibleNarration.audibleOurPrice=&submit.one-click-order.x=Comprar+en+1-Clic',
        author: 'Silvia H Prost',
        available: true,
        collection: 'Viaje filosófico',
        date: '26 Octubre 2017',
        description:
            'Ensayo filosófico que propone una mirada novedosa para debatir posibles explicaciones a algunos misterios de la Física real relacionados con el desarrollo de la Cuántica. Mientras los especialistas están buscando una teoría única que unifique los postulados contradictorios que hasta hoy ofrecen una explicación de la realidad, este libro se pregunta acerca de la posibilidad de auto observar la propia racionalidad humana, cuestionando el sentido común que la entiende única. ¿Qué pasaría si descubriéramos que la racionalidad con la que conceptualizamos el universo es solo una entre muchas posibilidades aún no desarrolladas? Se anuncia tal vez, la de un mundo infinitamente múltiple y coherente.',
        ebook: true,
        ebookPrice: '5,45',
        edition: '1er edición',
        editorial: 'Chiado',
        format: 'Disponible en E-book y papel',
        image: ensayoRacionalidadCover,
        isbn: '9789895208654',
        link: 'ensayo-sobre-la-racionalidad',
        new: true,
        pages: 57,
        presale: false,
        price: '4.521,00',
        pseudonimo: false,
        soldBy: [],
        spine: ensayoRacionalidadSpine,
        title: 'Ensayo sobre la racionalidad',
        year: '2017',
    },
    {
        id: 4,
        amazonlink:
            'https://www.amazon.com/dppui/pay-select?ie=UTF8&ASIN.0=B08XKGQ6PL&isPaymentInstrumentSelected=0&isPreorder=0&message=Customer+hasn%27t+declared+residence+address&result=NEED_DECLARED_ADDRESS&asin=B08XKGQ6PL&displayedPrice=7.1&displayedPriceCurrency=USD&displayedPriceCurrencyCode=USD&site=RetailWebsite&_token=gzXuovBbfWGqkIE%2Bnx0bNTl%2BA3%2FNF5EoInF%2BrYLpT1r%2FAAAADAAAAABlmblVcmF3AAAAABVX8CwXqz4nuL9RKX%2F%2F%2Fw%3D%3D&preferenceType=DefaultOneClick&clientId=dbs&t=fiona&isSeriesPurchase=0&device.encryptedDeviceAccountId=DT%3AA2CTZ977SKFQZY&transactionContext=one-click&destinationPath=%2Fapi%2Fbifrost%2Facquisitions%2Fv1%2Factions%2Farn%3Aaction%3Apa%3A1%3AQjA4WEtHUTZQTDpBVFZQREtJS1gwREVSOkJ1eTpudWxsOjcuMTA6VVNEOm51bGw%3D%3Fcsrf%3Dg%252BAsWu3m8hZUdb%252FeF%252BbyRfh7aGfdlHqDkj87efiuMTinAAAAAQAAAABlmblEcmF3AAAAAKs%252BFBXVfD4nuL9rqj%252BgAw%253D%253D%26x-client-id%3Debook-dp&bifrost_enabled=1&payment.mode=one-click&items%5B0%5D.audibleNarration.audibleAsin=&x-client-id=ebook-dp&ref_=dbs_p_ebk_r00_pbcb_cv1c00&csrf=g%2BAsWu3m8hZUdb%2FeF%2BbyRfh7aGfdlHqDkj87efiuMTinAAAAAQAAAABlmblEcmF3AAAAAKs%2BFBXVfD4nuL9rqj%2BgAw%3D%3D&items%5B0%5D.audibleNarration.addNarration=0&items%5B0%5D.audibleNarration.audibleOurPrice=&submit.one-click-order.x=Comprar+en+1-Clic',
        author: 'Silvia H Prost',
        available: true,
        date: '28 Enero 2021',
        description:
            'Ensayo orientado a la reflexión de quienes se enfrentan a la difícil tarea de ser padres. Explica algunas de las prácticas familiares que se adoptan en la actualidad, sin pensar demasiado, pero que son muy nocivas para la educación de los hijos como, por ejemplo, no poner límites o convencerlos de que son genios. Ser padres nunca fue sencillo, mucho menos en estos tiempos, donde vivimos acelerados y acosados por nuevas realidades como la incertidumbre planetaria y la pandemia. En este contexto, el libro tiene la intención de acompañar esa mirada hacia adentro que ayude a los educadores a reflexionar, no para culpabilizarse ni exigirse la perfección, sino para ocuparse de este tema tan importante y forjar una buena vida para los hijos.',
        ebook: true,
        ebookPrice: '7,10',
        edition: '1er edición',
        editorial: 'Editorial Autores de Argentina',
        format: 'Disponible en E-book y papel',
        image: veinteCosasCover,
        ipadImage: veinteCosasIpad,
        isbn: '9789878711294',
        link: 'veinte-cosas-que-usted-puede-hacer-para-arruinar-la-vida-de-su-hijo',
        new: true,
        pages: 166,
        phoneImage: veinteCosasPhone,
        price: '11.021,00',
        presale: false,
        pseudonimo: false,
        soldBy: [],
        spine: veinteCosasSpine,
        subtitle: 'Reflexiones sobre Educación Familiar',
        title: 'Veinte cosas que usted puede hacer para arruinar la vida de su hijo',
        year: '2021',
    },
    {
        id: 5,
        amazonlink: '',
        author: 'C. Pérez Torres',
        available: true,
        date: '2011',
        description:
            'Híbrido satírico tipo panfleto y/o manual de lavadora que enumera qué tener en cuenta y qué hacer para ser un cabal corrupto argentino. El libro recorre algunos temas como preguntarse si se nace corrupto o si se hace a lo largo de la vida. También se desarrollan los principios y deberes que el corrupto argentino tiene para consigo mismo, los demás, los otros corruptos, la Patria y la humanidad. Hay también un apartado dedicado a la importancia de la palabra y el gesto para capturar la credibilidad. Al final, el relato “Historia de un inodoro” funciona como una metáfora real de lo expresado en la primera parte.',
        ebook: false,
        ebookPrice: '?',
        edition: '1er edición',
        editorial: 'Dunken',
        format: 'Disponible en papel',
        image: manualDelCorruptoCover,
        isbn: '9789870253945',
        link: 'manual-del-corrupto-argentino',
        new: true,
        pages: 55,
        presale: false,
        price: '7.082,4',
        pseudonimo: true,
        soldBy: [],
        spine: manualDelCorruptoSpine,
        title: 'Manual del corrupto argentino',
        year: '2011',
    },
    {
        id: 6,
        amazonlink: '',
        author: 'C. Pérez Torres',
        available: false,
        date: '1 de Noviembre de 2006',
        description:
            'Ensayo que desarrolla algunos de los aspectos más relevantes de la decadencia educativa de finales del Siglo XX hurgando en los sótanos de la escuela argentina.',
        edition: '1er edición',
        editorial: 'Dunken',
        format: 'Disponible en papel',
        image: escuelaQueEnfermaCover,
        isbn: ' 9789875645844',
        link: 'la-escuela-que-enferma',
        new: true,
        pages: 112,
        presale: false,
        pseudonimo: true,
        soldBy: '',
        spine: escuelaQueEnfermaSpine,
        title: 'La escuela que enferma',
        year: '2006',
    },
];

export default books;
