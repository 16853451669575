import React from 'react';
import PolicyPage from './policyPage';

const PrivacyPolicy = () => {
    const title = 'Política de Privacidad';
    const content = [
        'En SilviaProst, respetamos tu privacidad y estamos comprometidos a proteger la información personal que puedas proporcionarnos. Esta política de privacidad explica cómo recopilamos, utilizamos y protegemos tus datos personales al utilizar nuestro sitio web.',
        'Información que recopilamos',
        'Recopilamos información personal que tú nos proporcionas directamente, como tu nombre, dirección de correo electrónico, dirección postal y número de teléfono. También podemos recopilar información no personal, como tu dirección IP, el tipo de navegador que estás utilizando y la URL de referencia.',
        'Uso de la información',
        'Utilizamos la información personal que recopilamos para proporcionarte los servicios que solicitaste y para mejorar nuestra oferta de productos y servicios. También podemos utilizar tu información para enviarte correos electrónicos de marketing o promocionales, pero solo si has dado tu consentimiento previo para recibirlos.',
        'Enlaces a otros sitios web',
        'Nuestro sitio web puede contener enlaces a otros sitios web que no están bajo nuestro control. No somos responsables de las prácticas de privacidad o el contenido de estos sitios web.',
        'Cambios en la política de privacidad',
        'Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Te recomendamos que revises esta página periódicamente para estar al tanto de cualquier cambio.',
        'Contacto',
        'Si tienes preguntas o comentarios sobre nuestra política de privacidad, no dudes en ponerte en contacto con nosotros a través de la información de contacto que se proporciona en nuestro sitio web.',
        'Esta política de privacidad fue actualizada por última vez el 12 de enero de 2024.',
    ];

    return <PolicyPage title={title} content={content} />;
};

export default PrivacyPolicy;
