import Camino from './../resources/articles/camino.jpg';

const essays = [
  {
      additionalInfo: 'El texto fue escrito hace algunos años, sin precisión exacta.',
      title: 'Sobre el sentido de la vida',
      date: '28 de abril, 2024',
      description: 'El sentido de la vida es una búsqueda personal, una pregunta profunda que nos invita a reflexionar sobre quiénes somos y por qué estamos aquí.',
      image: Camino,
      link: 'sobre-el-sentido-de-la-vida',
      text: [
          'Cada cosa tiene su para qué, y por eso nunca se te ocurriría utilizar una tijera como martillo o preparar un alimento en una maceta.  Como diría el filósofo español Julián Marías, las cosas salen mal o alguien se perjudica cuando usamos mal lo que son.  En general no suele haber discusión acerca de para qué sirve cada cosa, incluso algunas, como las lavadoras, vienen con manual de instrucción. Ahora bien, si hablamos de los seres vivos, ya puede haber disidencias. Ante la pregunta: para qué existe una planta, como el trigo, cuál es el sentido de su existencia en el medio del campo; pueden ensayarse muchas respuestas: para convertirse en pan, para embellecer la pradera, para alimentar a las aves, entre otras opciones.  Del mismo modo, si nos preguntamos para qué existen los flamencos del reino animal, por ejemplo, seguramente habrá diferentes opiniones, aunque estamos seguros de su belleza.  Pero qué ocurre cuando nos preguntamos para qué existe el hombre, o cuál es el sentido de que exista alguien.  Las respuestas se multiplican aún más.  La Madre Teresa o Gandhi dirían que es el amor, aunque para un ermitaño como Zarathustra o el vecino refunfuñador de la cuadra, ya no estaríamos tan seguros.  Es muy probable que el vecino ni se preguntara acerca del sentido de la vida.  De hecho, convengamos en que la mayoría de la gente no reflexiona acerca del “sentido de la vida”.  Es muy común que la muerte nos sorprenda sin habernos preguntado algo similar.',
          'El sentido de la vida puede parecer “aquello para lo cual” uno fue creado, y esto tiene que ver con conocer “qué somos”, o más precisamente “quién soy”, “para qué estamos en el mundo”.  Y puede decirse que llegar a esta pregunta qué es “la pregunta”, a la que no todos llegan, es iniciar una búsqueda que probablemente no acabe jamás.  Pero estar en esa búsqueda es ya encontrar un poco el sentido, porque el hombre, como ninguna otra criatura es camino, apertura, libertad de ser como se proponga.  Dice T. S. Eliot: “Hasta que no extiendas tus alas nadie puede saber lo alto que llegarás”, ni siquiera tú llegas a saberlo, podríamos agregar.  Preguntarse por el sentido de “la vida” es haber llegado a la médula de la humanidad, es como haber hecho el esfuerzo de subir y estar ahora sobre la cima de la montaña mirando el paisaje empequeñecido.  Y estar así, en silencio,  inundado por una pregunta tan misteriosa como profunda, tan abarcadora como esencial.  Y quiero decir además:   no hay “la respuesta”, no hay “dos opciones”, “ni seis” entre las cuales elegir; nadie tiene el manual de instrucción de  su vida, mucho menos de la vida de otro.  Lo cual no deja de ser  maravilloso.  Sería decepcionante que mientras reposamos en la montaña, alguien nos descifrara los colores que nos tiene anonadados, como si alguien conociera el manual de su destino, como en la lavadora.',
          '“El sentido de la vida” es  trabajo de cada uno.  Es un camino que se recorre con la admiración de haber llegado a la pregunta, un sendero en el cuál los otros son, además de compañía, a veces  indicio.  El alma y el cuerpo suelen decir cuando nos acercamos y la felicidad se señala al transitar.  Y da lo mismo si mientras recorres barrer las veredas, investigas en la academia, curar enfermos o cultivar verduras; porque el sentido es el camino.  La actividad que harás mientras tanto es sólo circunstancial, un detalle.  Por eso lo importante no es lo que “tenés”, lo que “sabés”, o los honores que has depositado en los otros la sociedad.  Lo esencial de la vida humana no son los “cómo” sino el “qué”, o más bien el “quién sos”.  Y este “quién” es la pregunta más profunda y radical del hombre, es estar frente al punto a partir del cual todo puede ser diferente.  Es darse cuenta que allí, en ausencia definitiva de explicaciones, en el silencio más absoluto del alma se puede comenzar la búsqueda de “sí mismo”.  Y es también, disfrutar el gozo más profundo que nos habilita el hecho de ser humanos.'
      ],
  }
];

export default essays;
