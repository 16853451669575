import { useNavigate } from 'react-router-dom';
import { Button, Center, Grid, Text } from '@chakra-ui/react';

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <>
            <Grid width={'100%'} height={'100%'} minH={'100vh'} textAlign={'center'}>
                <Center width={'100%'} height={'100%'} flexDirection={'column'}>
                    <Text fontSize={{ base: '10em', md: '14em' }} fontWeight={'bold'}>
                        404
                    </Text>
                    <Text fontSize={'1.2em'} fontWeight={'bold'} marginBottom={'30px'}>
                        Página no encontrada
                    </Text>
                    <Button
                        onClick={() => navigate('/')}
                        backgroundColor={'#724162'}
                        color={'white'}
                        _hover={{ backgroundColo: 'transparent' }}
                    >
                        Regresar a silviaprost.com
                    </Button>
                </Center>
            </Grid>
        </>
    );
};

export default NotFound;
