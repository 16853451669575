import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';

// Resources
import silvia from '../../../resources/silvia2.png';

// Styles
import './styles.scss';

const ContactBanner = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/contacto');
    }

    return (
        <Box className="contactBanner">
            <Flex className="content">
                <Box className="left"></Box>
                <Image className="image" src={silvia} />
                <Flex className="right">
                    <Text className="title">Envíale un mensaje a Silvia</Text>
                    <Button className="button" onClick={() => handleClick()}>Contacto</Button>
                </Flex>
            </Flex>
        </Box>
    )
}

export default ContactBanner;