import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Divider,
    Flex,
    HStack,
    Image,
    Text,
} from '@chakra-ui/react';

// Components
import ShareLink from '../../components/share/share';

// TODO: implement a database and remove this
// Data
import articles from '../../database/articles';
import essays from '../../database/essays';

// Resources
import silvia from './../../resources/silvia2.png';

// Styles
import './styles.scss';
import Footer from '../../components/footer/footer';
import BackButton from '../../components/ui/back-button';
import ArticleForm from '../../components/form/articleForm';

const Article = () => {

    const { link } = useParams();
    const article = articles.find((article) => article.link === link);
    const essay = essays.find((essay) => essay.link === link);
    const item = article || essay;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <BackButton link={'/blog'} />
            <Flex className='article'>
                <Flex className='article-left'>
                    <Text className='article-title'>{item?.title}</Text>
                    <HStack marginTop="2" spacing="5" display="flex"  justifyContent={'space-between'} alignItems="center" width={'80%'}>
                        <Flex gap={'20px'}>
                            <Image
                                marginTop={'10px'}
                                width={'70px'}
                                src={silvia}
                                alt={'Foto de silvia'}
                                objectFit={'cover'}
                                clipPath='polygon(0 0, 100% 0, 100% 80%, 0 80%)'
                            />
                            <Flex flexDirection={'column'} justifyContent={'flex-end'} textAlign={'start'} maxH={'100px'}>
                                <Text as={'b'}>Silvia H. Prost</Text>
                                <Text display={'flex'} fontSize={'14px'}>
                                    <Box paddingTop={1} paddingRight={1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                        </svg>
                                    </Box>
                                    {item?.minutes} min.
                                </Text>
                            </Flex>
                        </Flex>
                    </HStack>
                    <Divider marginY={'2px'} color={'red'} />
                    <HStack spacing="5" display="flex"  justifyContent={'space-between'} alignItems="center" width={'80%'}>
                        <Text className='article-date'>
                            <Text>{item?.date}</Text>
                        </Text>
                        <ShareLink link={'blog/' + link}/>
                    </HStack>
                    <Box className='article-image'>
                        {item?.image &&
                        <Image
                            src={item?.image}
                            alt={item?.title}
                            objectFit="contain"
                        />}
                    </Box>
                    {item?.text.map((paragraph, index) => (
                        <Text className='article-text' key={index}>
                            {paragraph}
                        </Text>
                    ))}
                    {item.additionalInfo && 
                        <Flex width='100%'>
                            <Text className='article-additionalinfo'>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 48 48"
                                >
                                    <path
                                        fill="#2196f3"
                                        d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                                    ></path>
                                    <path
                                        fill="#fff"
                                        d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"
                                    ></path>
                                </svg>
                                {item.additionalInfo}
                            </Text>
                        </Flex>
                    }
                </Flex>
                <Flex className='article-right'>
                    <Flex className='article-register'>
                        <Text className='article-register-title'>No te pierdas ningún artículo</Text>
                        <Text className='article-register-subtitle'>Mantente al tanto de nuestras últimas publicaciones en el blog! Regístrate con tu correo electrónico para recibir notificaciones directamente en tu bandeja de entrada. No querrás perderte las últimas noticias, consejos y contenido exclusivo.</Text>
                        <ArticleForm />
                        <Text className={'recaptcha'} paddingTop={'10px'}>
                            This site is protected by reCAPTCHA and the Google&nbsp;
                            <a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp; and&nbsp;
                            <a href="https://policies.google.com/terms">Terms of Service</a>&nbsp; apply.
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
            <Footer />
        </>
    )
}

export default Article;
