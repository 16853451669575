export const defaultNavigationOptions = [
    {
        title: 'Inicio',
        link: '/inicio',
    },
    {
        title: 'Biografía',
        link: '/biografia',
    },
    {
        title: 'Libros',
        link: '/libros',
    },
    {
        title: 'Pinturas',
        link: '/pinturas',
    },
    {
        title: 'Blog',
        link: '/blog',
    },
    {
        title: 'Contacto',
        link: '/contacto',
    },
]