import { Box, Button, Center, Flex, Grid, Image, Text } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';

// Resources
import paintings from '../../../database/paintings';

// Styles
import './styles.scss';

const PaintingsBanner = ({color}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/pinturas');
    }

    return (
        <Grid className="paintingsBanner" backgroundColor={color} clipPath={'polygon(1px 80px, 100% 0px, 100% 15%, 99.94% 18%, 100% 22%, 99.94% 26%, 100% 30%, 99.94% 34%, 100% 38%, 99.94% 42%, 100% 46%, 99.94% 50%, 100% 54%, 99.86% 58%, 100% 62%, 99.94% 66%, 100% 70%, 99.94% 74%, 100% 78%, 99.94% 82%, 99.93% 83.55%, 99.87% 80.9%, 99.93% 79.46%, 99.94% 80.34%, 99.78% 84.92%, 0% 100%, 0% 98%, 0.06% 92%, 0% 86%, 0% 82%, 0.06% 76%, 0% 72%, 0.06% 68%, 0% 64%, 0.06% 60%, 0% 56%, 0% 52%, 0.06% 48%, 0% 44%, 0.06% 40%, 0% 36%, 0.06% 32%, 0% 28%, 0% 24%, 0.06% 20%, 0% 16%, 0.06% 12%, 0% 10.58%, 0.06% 11.2%, 0% 88px)'}>
            <Center
                className="head"
                justifyContent={'space-around'}
                flexDirection={{ base: 'column', md: 'row' }}
            >
                <Text className="title">Mis Pinturas</Text>
                <Button className="button" onClick={() => handleClick()}>
                    Ir
                </Button>
            </Center>
            <Flex className="content">
                <Flex className="row1">
                    <Image className="row1img" src={paintings[0].image} />
                    <Image className="row1img" src={paintings[5].image} />
                </Flex>
                <Flex className="row2">
                    <Box className="left">
                        <Image src={paintings[7].image} />
                    </Box>
                    <Grid className='right'>
                        <Flex className="bottom">
                            <Image className="row2img" src={paintings[16].image} />
                            <Image className="row2img" src={paintings[11].image} />
                        </Flex>
                    </Grid>
                </Flex>
            </Flex>
        </Grid>
    )
}

export default PaintingsBanner;