import { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import { Box, Flex, HStack, Button, useDisclosure, Stack, Text, Fade } from '@chakra-ui/react';

// Options
import { defaultNavigationOptions } from './options';

// Styles
import './styles.scss';

export default function Navbar() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [style, setStyle] = useState(false);

    window.addEventListener(
        'scroll',
        () => setStyle(window.scrollY >= 90)
    );

    const mobileButton = (icon) => {
        return (
            <Button
                className="optionsbutton"
                size={'md'}
                backgroundColor={'transparent'}
                onClick={isOpen ? onClose : onOpen}
                display={{ md: 'none' }}
            >
                {icon}
            </Button>
        )
    }

    return (
        <>
            <Box className={style ? 'navbar-s' : 'newnavbar'}>
                <Flex
                    className="content"
                    h={16}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    zIndex={9999}
                >
                    <Link to="/" className="logolink">
                        <Text className="logo">SilviaHProst</Text>
                    </Link>
                    <HStack className="options" as={'nav'} spacing={4}>
                        {defaultNavigationOptions.map((option, index) =>
                            <Nav.Link key={index} className="optionlink" as={Link} to={option.link}>
                                {option.title}
                            </Nav.Link>
                        )}
                    </HStack>
                    {isOpen ? (
                        mobileButton(<svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-x-lg"
                            viewBox="0 0 16 16"
                        >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>)
                    ) : (
                        mobileButton(<svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-list"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fillRule="evenodd"
                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                            />
                        </svg>)
                    )}
                </Flex>
                {isOpen ? (
                    <Fade in={isOpen}>
                        <Stack className="optionsMobile" as={'nav'} spacing={4}>
                            {defaultNavigationOptions.map((option, index) =>
                                <Nav.Link key={index} className="optionlink" as={Link} to={option.link} onClick={() => onClose()}>
                                    {option.title}
                                </Nav.Link>
                            )}
                        </Stack>
                    </Fade>
                ) : null}
            </Box>
            <section style={{maxWidth:'1920px', margin:'auto'}}>
                <Outlet></Outlet>
            </section>
        </>
    );
}
