import c1_1 from '../resources/paintings/C1_1.webp';
import c1_2 from '../resources/paintings/C1_2.webp';
import c1_3 from '../resources/paintings/C1_3.webp';
import c1_4 from '../resources/paintings/C1_4.webp';
import c1_5 from '../resources/paintings/C1_5.webp';
import c1_6 from '../resources/paintings/C1_6.webp';
import c1_7 from '../resources/paintings/C1_7.webp';
import c1_8 from '../resources/paintings/C1_8.webp';
import c1_9 from '../resources/paintings/C1_9.webp';
import c1_10 from '../resources/paintings/C1_10.webp';
import c2_1 from '../resources/paintings/C2_1.webp';
import c2_2 from '../resources/paintings/C2_2.webp';
import c2_3 from '../resources/paintings/C2_3.webp';
import c2_4 from '../resources/paintings/C2_4.webp';
import c2_5 from '../resources/paintings/C2_5.webp';
import c2_6 from '../resources/paintings/C2_6.webp';
import c2_7 from '../resources/paintings/C2_7.webp';
import c3_1 from '../resources/paintings/C3_1.webp';
import c3_2 from '../resources/paintings/C3_2.webp';
import c3_3 from '../resources/paintings/C3_3.webp';
import c3_4 from '../resources/paintings/C3_4.webp';
import c3_5 from '../resources/paintings/C3_5.webp';
import c4_1 from '../resources/paintings/C4_1.webp';
import c4_2 from '../resources/paintings/C4_2.webp';
import c4_3 from '../resources/paintings/C4_3.webp';
import c4_4 from '../resources/paintings/C4_4.webp';
import c5_1 from '../resources/paintings/C5_1.webp';
import c5_2 from '../resources/paintings/C5_2.webp';
import c6_1 from '../resources/paintings/C6_1.webp';

import c1_1hd from '../resources/paintingsHD/C1_1.webp';
import c1_2hd from '../resources/paintingsHD/C1_2.webp';
import c1_3hd from '../resources/paintingsHD/C1_3.webp';
import c1_4hd from '../resources/paintingsHD/C1_4.webp';
import c1_5hd from '../resources/paintingsHD/C1_5.webp';
import c1_6hd from '../resources/paintingsHD/C1_6.webp';
import c1_7hd from '../resources/paintingsHD/C1_7.webp';
import c1_8hd from '../resources/paintingsHD/C1_8.webp';
import c1_9hd from '../resources/paintingsHD/C1_9.webp';
import c1_10hd from '../resources/paintingsHD/C1_10.webp';
import c2_1hd from '../resources/paintingsHD/C2_1.webp';
import c2_2hd from '../resources/paintingsHD/C2_2.webp';
import c2_3hd from '../resources/paintingsHD/C2_3.webp';
import c2_4hd from '../resources/paintingsHD/C2_4.webp';
import c2_5hd from '../resources/paintingsHD/C2_5.webp';
import c2_6hd from '../resources/paintingsHD/C2_6.webp';
import c2_7hd from '../resources/paintingsHD/C2_7.webp';
import c3_1hd from '../resources/paintingsHD/C3_1.webp';
import c3_2hd from '../resources/paintingsHD/C3_2.webp';
import c3_3hd from '../resources/paintingsHD/C3_3.webp';
import c3_4hd from '../resources/paintingsHD/C3_4.webp';
import c3_5hd from '../resources/paintingsHD/C3_5.webp';
import c4_1hd from '../resources/paintingsHD/C4_1.webp';
import c4_2hd from '../resources/paintingsHD/C4_2.webp';
import c4_3hd from '../resources/paintingsHD/C4_3.webp';
import c4_4hd from '../resources/paintingsHD/C4_4.webp';
import c5_1hd from '../resources/paintingsHD/C5_1.webp';
import c5_2hd from '../resources/paintingsHD/C5_2.webp';
import c6_1hd from '../resources/paintingsHD/C6_1.webp';

const paintings = [
    {
        id: 29,
        title: 'Caldén sin hojas',
        image: c6_1,
        imagehd: c6_1hd,
        materials: 'Óleo sobre tela',
        size: '110 x 80',
        year: '2023',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 1,
        title: 'Caldén morado',
        image: c5_1,
        imagehd: c5_1hd,
        materials: 'Óleo sobre madera',
        size: '50 X 70',
        year: '2023',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 2,
        title: 'Soleado',
        image: c5_2,
        imagehd: c5_2hd,
        materials: 'Óleo sobre tela',
        size: '110x80',
        date: 'Mayo 2023',
        year: '2023',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 3,
        title: 'Destello de cactus',
        image: c2_5,
        imagehd: c2_5hd,
        materials: 'Óleo sobre tela',
        size: '50 X 70',
        year: '2023',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 4,
        title: 'Luna y fragata en Madryn',
        image: c1_4,
        imagehd: c1_4hd,
        materials: 'Óleo sobre papel',
        size: '50 x 60',
        year: '2022',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 5,
        title: 'Caldén helado',
        image: c2_7,
        imagehd: c2_7hd,
        materials: 'Óleo sobre tela',
        size: '50 X 70',
        year: '2021',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 6,
        title: 'Jane Austen',
        image: c1_6,
        imagehd: c1_6hd,
        materials: 'Acrílico sobre papel',
        size: '34 x 44',
        year: '2022',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 7,
        title: 'Cotita Cambaceres',
        image: c1_1,
        imagehd: c1_1hd,
        materials: 'Acrílico sobre papel',
        size: '30 x 40',
        year: '2022',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 8,
        title: 'Niño menonita',
        image: c1_2,
        imagehd: c1_2hd,
        materials: 'Óleo sobre papel',
        size: '36 × 46',
        year: '2022',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 9,
        title: 'Autorretrato infantil escolar',
        image: c4_1,
        imagehd: c4_1hd,
        materials: 'Acrílico sobre papel',
        size: '35 x 50',
        year: '2022',
        price: 'Consultar',
        onSale: false,
        encuadrado: true,
    },
    {
        id: 10,
        title: 'Tronco y chaucha',
        image: c2_6,
        imagehd: c2_6hd,
        materials: 'Óleo sobre tela',
        size: '50 X 70',
        year: '2021',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 11,
        title: 'Autorretrato infantil',
        image: c2_1,
        imagehd: c2_1hd,
        materials: 'Acrílico sobre papel',
        size: '43 X 58',
        year: '2022',
        price: 'Consultar',
        onSale: false,
        encuadrado: true,
    },
    {
        id: 12,
        title: 'El Juanito Laguna de Berni',
        image: c1_8,
        imagehd: c1_8hd,
        materials: 'Acrílico sobre papel',
        size: '34 x 44',
        year: '2021',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 13,
        title: 'El chañar',
        image: c1_9,
        imagehd: c1_9hd,
        materials: 'Acrílico sobre papel',
        size: '34 x 44',
        year: '2021',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 14,
        title: 'Serie corazones - Corazón partido',
        image: c2_4,
        imagehd: c2_4hd,
        materials: 'Acrílico sobre papel',
        size: '42 X 52',
        year: '2018',
        price: 'Consultar',
        onSale: true,
        encuadrado: false,
    },
    {
        id: 15,
        title: 'Bosque de cactus',
        image: c1_10,
        imagehd: c1_10hd,
        materials: 'Óleo sobre tela',
        size: '50 x 70',
        year: '2022',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 16,
        title: 'Luna roja',
        image: c2_2,
        imagehd: c2_2hd,
        materials: 'Acrílico sobre papel',
        size: '21 x 28',
        year: '2021',
        price: 'Consultar',
        onSale: true,
        encuadrado: false,
    },
    {
        id: 17,
        title: 'Flor y luna',
        image: c2_3,
        imagehd: c2_3hd,
        materials: 'Óleo sobre papel',
        size: '42 X 52',
        year: '2022',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 18,
        title: 'Caballo y laguna',
        image: c1_7,
        imagehd: c1_7hd,
        materials: 'Acrílico sobre papel',
        size: '34 x 44',
        year: '2022',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 19,
        title: 'El encuentro',
        image: c1_5,
        imagehd: c1_5hd,
        materials: 'Óleo sobre tela',
        size: '50 × 70',
        year: '2021',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 20,
        title: 'Monte pampeano',
        image: c4_3,
        imagehd: c4_3hd,
        materials: 'Óleo sobre papel',
        size: '35 x 50',
        year: '2021',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 21,
        title: 'Maníes',
        image: c3_2,
        imagehd: c3_2hd,
        materials: 'Collage sobre papel',
        size: '32 x 45',
        year: '2022',
        price: 'Consultar',
        onSale: true,
        encuadrado: false,
    },
    {
        id: 22,
        title: 'Bosque de cactus y caldenes',
        image: c3_3,
        imagehd: c3_3hd,
        materials: 'Óleo sobre papel',
        size: '21 x 28',
        year: '2022',
        price: 'Consultar',
        onSale: true,
        encuadrado: false,
    },
    {
        id: 23,
        title: 'Serie corazones - Brotando ',
        image: c3_4,
        imagehd: c3_4hd,
        materials: 'Acrílico sobre papel',
        size: '42 x 52',
        year: '2021',
        price: 'Consultar',
        onSale: true,
        encuadrado: false,
    },
    {
        id: 24,
        title: 'Niña menonita',
        image: c1_3,
        imagehd: c1_3hd,
        materials: 'Óleo sobre papel',
        size: '43 x 54',
        year: '2022',
        price: 'Consultar',
        onSale: true,
        encuadrado: true,
    },
    {
        id: 25,
        title: 'Serie corazones - Enamorados',
        image: c4_2,
        imagehd: c4_2hd,
        materials: 'Acrílico sobre papel',
        size: '42 x 52',
        year: '2018',
        price: 'Consultar',
        onSale: true,
        encuadrado: false,
    },
    {
        id: 26,
        title: 'Educación  en libertad',
        image: c3_1,
        imagehd: c3_1hd,
        materials: 'Acrílico sobre papel',
        size: '15 x 21',
        year: '2022',
        price: 'Consultar',
        onSale: true,
        encuadrado: false,
    },
    {
        id: 27,
        title: 'Serie corazones - Lamento nocturno',
        image: c4_4,
        imagehd: c4_4hd,
        materials: 'Acrílico sobre papel',
        size: '34 x 44',
        year: '2018',
        price: 'Consultar',
        onSale: false,
        encuadrado: true,
    },
    {
        id: 28,
        title: 'Jardín',
        image: c3_5,
        imagehd: c3_5hd,
        materials: 'Óleo sobre papel',
        size: '42 x 52',
        year: '2021',
        price: 'Consultar',
        onSale: true,
        encuadrado: false,
    },
];

export default paintings;
