// utilities.js

export const validateName = /^[a-zA-Z\u00C0-\u00FF']+$/;

export function validateEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function validateContact(data, errors, setErrors) {
    if (
        data.fullname === '' ||
        data.fullname.split(' ', 10).some((item) => !validateName.test(item))
    ) {
        setErrors({ ...errors, fullname: true });
        return false;
    }
    if (data.email === '' || !validateEmail(data.email) || data.email.length > 75) {
        setErrors({ ...errors, email: true });
        return false;
    }
    if (data.message && data.message === '') {
        setErrors({ ...errors, message: true });
        return false;
    }

    return true;
}

export function validateOrder(data, errors, setErrors) {
    if (
        data.fullname === '' ||
        data.fullname.split(' ', 10).some((item) => !validateName.test(item))
    ) {
        setErrors({ ...errors, fullname: true });
        return false;
    }
    if (data.email === '' || !validateEmail(data.email) || data.email.length > 75) {
        setErrors({ ...errors, email: true });
        return false;
    }
    if (data.phone === '') {
        setErrors({ ...errors, phone: true });
        return false;
    }
    if (data.address === '') {
        setErrors({ ...errors, address: true });
        return false;
    }
    if (data.comments && data.comments === '') {
        setErrors({ ...errors, comments: true });
        return false;
    }

    return true;
}
