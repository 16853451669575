import { useState } from 'react';
import {
    Button,
    Box,
    Flex,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import CopyToClipboard from 'react-copy-to-clipboard';

// Styles
import './styles.scss';

const ShareLink = ({link}) => {

    const [copied, setCopied] = useState(false);
    const url = 'https://silviaprost.com/' + link;
    const iconSize = '16';

    const hashtag = 'SilviaProstArticulos';
    const text = `¡Echa un vistazo a este increíble artículo de Silvia Prost #${hashtag}\n`;

    const handleCopySuccess = () => {
        setCopied(true)
    };

    const handleEmail = () => {
        const subject = 'Mira este artículo de Silvia Prost!';
        const emailUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(text)}%0A%0A${encodeURIComponent(url)}`;
        window.location.href = emailUrl;
      };

    const handleFacebook = () => {
        const text = `¡Echa un vistazo a este increíble artículo de Silvia Prost!`;
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(text)}`;
        window.open(facebookUrl, '_blank');
    };

    const handleWhatsApp = () => {
        const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(text)}%20${encodeURIComponent(url)}`;
        window.open(whatsappUrl, '_blank');
    };

    const handleX = () => {
        const xUrl = `https://x.com/intent/tweet?url=${encodeURIComponent('https://silviaprost.com/blog/que-derechos-defiende-utelpa')}&text=${encodeURIComponent(text)}`;
        window.open(xUrl, '_blank');
    };

    return (
        <Box className='share'>
            <Popover>
            <PopoverTrigger>
                <Button className='share-button'>
                    <svg xmlns="http://www.w3.org/2000/svg" width={'13px'} height={'13px'} fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                        <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5"/>
                    </svg>
                    &nbsp;&nbsp;
                    <Text className='share-button-text'>Compartir</Text>
                </Button>
            </PopoverTrigger>
            <PopoverContent className='share-popover'>
                <PopoverBody className='popover-body'>
                    <Flex className='popover-content'>
                        <Button onClick={() => handleX()} className='popover-content-button' backgroundColor={'transparent'} borderRadius={'10px'} _hover={{backgroundColor:'transparent'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={iconSize} height={iconSize} fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                            </svg>&nbsp;
                            <Text fontWeight={'normal'}>Twitter (X)</Text>
                        </Button>
                        <Button onClick={() => handleFacebook()} className='popover-content-button' backgroundColor={'transparent'} borderRadius={'10px'} _hover={{backgroundColor:'transparent'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={iconSize} height={iconSize} fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                            </svg>
                            &nbsp;
                            <Text fontWeight={'normal'}>Facebook</Text>
                        </Button>
                        <Button onClick={() => handleWhatsApp()} className='popover-content-button' backgroundColor={'transparent'} borderRadius={'10px'} _hover={{backgroundColor:'transparent'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={iconSize} height={iconSize} fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                            <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                        </svg>&nbsp;
                            <Text fontWeight={'normal'}>WhatsApp</Text>
                        </Button>
                        <CopyToClipboard text={url} onCopy={handleCopySuccess}>
                            <Button className='popover-content-button' backgroundColor={'transparent'} borderRadius={'10px'} _hover={{backgroundColor:'transparent'}}>
                                <Tooltip label="Compartir" aria-label='A tooltip'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={iconSize} height={iconSize} fill="currentColor" className="bi bi-share" viewBox="0 0 16 16">
                                        <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3" />
                                    </svg>
                                </Tooltip>
                                {!copied ?
                                    <Text paddingLeft={'8px'} fontWeight={'normal'}>
                                        Copiar enlace
                                    </Text>
                                    :
                                    <Text paddingLeft={'8px'} fontWeight={'normal'} color={'green.400'}>
                                        Enlace copiado
                                    </Text>
                                    }
                            </Button>
                        </CopyToClipboard>
                        <Button onClick={() => handleEmail()} className='popover-content-button' backgroundColor={'transparent'} borderRadius={'10px'} _hover={{backgroundColor:'transparent'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={iconSize} height={iconSize} fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                        </svg>
                        &nbsp;
                            <Text fontWeight={'normal'}>Email</Text>
                        </Button>
                    </Flex>
                </PopoverBody>
            </PopoverContent>
            </Popover>
        </Box>
    );
};

export default ShareLink;
