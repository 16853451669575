import { Box, Divider, Flex, Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';

// Styles
import './styles.scss';

const BookSkeleton = () => {

    return (
        <>
            <SkeletonText className={'book-skeleton-topbar'} noOfLines={1} skeletonHeight='5' />
            <Flex className="book-skeleton-content">
                <Skeleton className='book-skeleton-image' />
                <Flex className="book-skeleton-information">
                    <Box className="book-skeleton-top">
                        <SkeletonText noOfLines={1} skeletonHeight='10' />
                        <SkeletonText mt='10' noOfLines={7} spacing='4' skeletonHeight='2' />
                    </Box>
                </Flex>
                <Skeleton className="book-skeleton-salebox" />
            </Flex>
            <SkeletonText className='book-skeleton-information-2' mt='10' noOfLines={8} spacing='4' skeletonHeight='2' />
        </>
    )
}

export default BookSkeleton;