import { Box, SimpleGrid, Grid, Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';

// Styles
import './styles.scss';

const PaintingsSkeleton = () => {

    const items = Array.from({ length: 9 }, (_, index) => index + 1);

    return (
        <>

            <Box
                className='paintings-skeleton'
            >
                <SimpleGrid
                    columns={[1, 2, 2, 3]}
                    spacing='10'
                >
                    {items.map((paint, index) => (
                        <Grid key={index} className='paint-grid-skeleton'>
                            <Skeleton className='paint-skeleton' key={index} />
                            <SkeletonText mt={4} noOfLines={5} skeletonHeight='3' width={'70%'}/>
                        </Grid>
                    ))}
                </SimpleGrid>
            </Box>
        </>
    )
}

export default PaintingsSkeleton;