import { useRef, useState } from 'react';
import { Center, Box, Button, Flex, Text, useBreakpointValue, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

// Resources
import books from './../../database/books';

// Styles
import './styles.scss';

export default function CarouselBooks() {

    const [startIndex, setStartIndex] = useState(0);
    const touchStart = useRef(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate = useNavigate();

    const itemsPerPage = useBreakpointValue({ base: 1, sm: 4 });

    const totalItems = books.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % totalPages);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + totalPages) % totalPages);
    };

    const handleClick = (url) => {
        navigate(url);
        window.scrollTo(0, 0);
    };

    const handleTouchStart = (e) => {
        touchStart.current = e.touches[0].clientX;
        setStartIndex(currentIndex);
    };

    const handleTouchMove = (e) => {
        const touchEnd = e.touches[0].clientX;
        const touchDiff = touchEnd - touchStart.current;
        const itemsPerPageWidth = 100 / itemsPerPage;
        const maxMove = Math.min(1, Math.floor(Math.abs(touchDiff) / itemsPerPageWidth));
        const moveIndex = touchDiff > 0 ? maxMove : -maxMove;
        const newIndex = startIndex - moveIndex;

        const totalItems = books.length;
        const clampedIndex = (newIndex + totalItems) % totalItems;

        setCurrentIndex(clampedIndex);
    };

    const handleTouchEnd = () => {
        setStartIndex(0);
    };

    return (
        <Flex
            className="books-carousel"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <Flex className="buttons">
                <Button className="button" onClick={prevSlide}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="32"
                        width="32"
                        viewBox="0 0 512 512"
                    >
                        <path d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM231 127c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-71 71L376 232c13.3 0 24 10.7 24 24s-10.7 24-24 24l-182.1 0 71 71c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L119 273c-9.4-9.4-9.4-24.6 0-33.9L231 127z" />
                    </svg>
                </Button>
                <Button className="button" onClick={nextSlide}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="32"
                        width="32"
                        viewBox="0 0 512 512"
                    >
                        <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
                    </svg>
                </Button>
            </Flex>
            <Center
                id="elementoDestino"
                className="top"
                justifyContent={'space-around'}
                flexDirection={{ base: 'column', md: 'row' }}
            >
                <Text className="title">Mis libros</Text>
                <Button className="button" onClick={() => handleClick('/libros')}>
                    Ver todos
                </Button>
            </Center>
            <Box className="content">
                <Flex
                    className="books"
                    transition="transform 0.3s"
                    transform={`translateX(-${currentIndex * (100 / itemsPerPage)}%)`}
                >
                    {books.map((book) => (
                        <Box
                            onClick={() => handleClick(`/libros/${book.link}`)}
                            className="item"
                            overflow="hidden"
                            key={book.id}
                            flex={`0 0 ${100 / itemsPerPage}%`}
                            p={2}
                        >
                            <Image className="image" src={book.image} />
                            <Text className="title" fontSize="lg" fontWeight="bold" p={2}>
                                {book.title}
                            </Text>
                        </Box>
                    ))}
                </Flex>
                <Flex className="carousel-dots" justifyContent="center" mt={4}>
                    {Array.from({ length: totalPages }).map((_, index) => (
                        <Box
                            key={index}
                            w={4}
                            h={4}
                            mx={2}
                            borderRadius="full"
                            bg={index === currentIndex ? '#724162' : 'gray.300'}
                            onClick={() => setCurrentIndex(index)}
                            cursor="pointer"
                        />
                    ))}
                </Flex>
            </Box>
        </Flex>
    );
}
