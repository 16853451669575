import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Components
import Article from './pages/article/article';
import Biography from './pages/biography/biography';
import Blog from './pages/blog/blog';
import Book from './pages/books/book';
import BookList from './pages/books/bookList';
import CookiesPolicy from './pages/policies/cookiesPolicy';
import Contact from './pages/contact/contact';
import Home from './pages/home/home';
import LegalWarning from './pages/policies/legalWarning';
import NavBar from './components/navbar/navbar';
import NotFound from './pages/notFound';
import Paintings from './pages/paintings/paintings';
import PrivacyPolicy from './pages/policies/privacyPolicy';
import ReactGA from 'react-ga';

// Google Analytics
const TRACKING_ID = 'UA-148787297-1';
ReactGA.initialize(TRACKING_ID);

// Styles
import './App.css';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<NavBar />}>
                        <Route path="/aviso-legal" element={<LegalWarning />} />
                        <Route path="/biografia" element={<Biography />} />
                        <Route path="/blog" element={<Blog />} />
                        <Route path="/blog/:link" element={<Article />} />
                        <Route path="/contacto" element={<Contact />} />
                        <Route path="/inicio" element={<Home />} />
                        <Route path="/libros" exact element={<BookList />} />
                        <Route path="/libros/:id" element={<Book />} />
                        <Route path="/pinturas" element={<Paintings />} />
                        <Route path="/pinturas/:id" element={<Paintings />} />
                        <Route path="/politica-de-cookies" element={<CookiesPolicy />} />
                        <Route path="/politica-de-privacidad" element={<PrivacyPolicy />} />
                        <Route path="/" element={<Navigate replace to="/inicio" />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
