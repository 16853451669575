import { useEffect, useState } from 'react';
import { Box, Center, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

// Components
import Footer from '../../components/footer/footer';
import Information from './information';
import Paint from './paint';

// Resources
import paintings from '../../database/paintings';
import PaintingsSkeleton from '../../components/skeleton/paintings';

const Paintings = () => {

    const { id } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedPaint, setSelectedPaint] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);

        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, 1500);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        if (id) {
            const paintToShow = paintings.find(paint => paint.id === parseInt(id, 10));
            if (paintToShow) {
                showInformation(paintToShow)
            }
        }
    }, [location.search]);

    const showInformation = (paint) => {
        setSelectedPaint(paint)
        onOpen()
    }

    return (
        <>
            {loading ?
                <PaintingsSkeleton />
                :
                <>
                    <Box
                        className='paintings'
                    >
                        <SimpleGrid
                            columns={[1, 2, 2, 3]}
                            spacing={{ base: 10, lg: 10 }}
                        >
                            {paintings.map((paint, index) => (
                                <Center className='paint' key={index}>
                                    <Paint paint={paint} handleClick={showInformation}/>
                                </Center>
                            ))}
                        </SimpleGrid>
                    </Box>
                </>
            }
            {selectedPaint && <Information isOpen={isOpen} onClose={onClose} paint={selectedPaint} />}
            <Footer />
        </>
    );
};

export default Paintings;
