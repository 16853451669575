import { useEffect } from 'react';
import { Grid, SimpleGrid, Fade, Image, Text, Center } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

// Components
import Footer from '../../components/footer/footer';
import LaEscuelaQueEstafaBanner from '../../components/banner/laEscuelaQueEstafa/laEscuelaQueEstafa';

// Resources
import data from '../../database/books';

// Styles
import './styles.scss';

const BookList = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleClick = (book) => {
        navigate(`/libros/${book.link}`);
    };

    return (
        <Grid className="booksPage">
            <Fade in={true}>
                <SimpleGrid className="booklist" columns={[1, 2, 3]}>
                    {data.map((book, index) => (
                        <Center key={index}>
                            <Grid className="book">
                                <Image
                                    className="image"
                                    key={index}
                                    src={book.image}
                                    onClick={() => handleClick(book)}
                                />
                                <div className="information">
                                    <Text className="title">{book.title}</Text>
                                    <Text className="date">{book.year}</Text>
                                </div>
                            </Grid>
                        </Center>
                    ))}
                </SimpleGrid>
            </Fade >
            <LaEscuelaQueEstafaBanner simple={true} />
            <Footer />
        </Grid>
    );
};

export default BookList;
