import React from 'react';
import PolicyPage from './policyPage';

const AvisoLegal = () => {
    const title = 'Aviso Legal';
    const content = [
        'Condiciones de uso del sitio web',
        'Estas son las condiciones generales (en adelante "condiciones generales") que regulan el acceso, navegación y uso de SilviaProst.com (en adelante, SilviaProst.com o la Web), así como las responsabilidades derivadas de la utilización de sus contenidos (entendiendo en adelante por "contenidos" los textos, gráficos, dibujos, diseños, códigos, software, fotografías, música, vídeos, sonidos, bases de datos, imágenes, expresiones e informaciones, así como cualquier otra creación protegida por las leyes nacionales y los tratados internacionales sobre propiedad intelectual e industrial).',
        'Se entenderá que el acceso o la mera utilización de SilviaProst.com por parte del Usuario implica la adhesión de éste a las condiciones generales publicadas en cada momento en el que acceda a SilviaProst.com. En consecuencia, el Usuario debe leer atentamente estas condiciones generales.',
        'En este sentido, se entenderá por Usuario a la persona que acceda, navegue, utilice o participe en los servicios y actividades, en SilviaProst.com.',
        'Asimismo, está prohibido modificar, copiar, reutilizar, explotar, reproducir, comunicar públicamente, hacer segundas o posteriores publicaciones, cargar archivos, enviar por correo, transmitir, usar, tratar o distribuir de cualquier forma la totalidad o parte de los contenidos incluidos en SilviaProst.com para propósitos públicos o comerciales, si no se cuenta con la autorización de Silvia Prost, en su caso, del titular de los derechos a que corresponda.',
        'SilviaProst.com no garantiza la licitud, fiabilidad, utilidad, veracidad o exactitud de los servicios o de la información que se presten a través de esta página.',
        'No obstante, SilviaProst.com adopta todas las medidas necesarias, dentro de sus posibilidades y del estado de la tecnología, para garantizar el funcionamiento de la web y evitar la existencia y transmisión de virus y demás componentes dañinos para el Usuario.',
        'En SilviaProst.com, el Usuario podrá encontrar enlaces a otras páginas web mediante diferentes botones, enlaces, banners, etc. SilviaProst.com no tiene facultad ni medios humanos ni técnicos para conocer, controlar ni aprobar toda la información, contenidos, productos o servicios facilitados por otros sitios web a los que se establecen enlaces desde SilviaProst.com. En consecuencia, SilviaProst.com no puede asumir ningún tipo de responsabilidad por cualquier aspecto relativo a la página web a la que se establece un enlace desde SilviaProst.com, en concreto, a título enunciativo y no taxativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos, en general.',
        'El establecimiento de cualquier tipo de enlace por parte de SilviaProst.com a otro sitio web ajeno no implica que exista algún tipo de relación, colaboración o dependencia entre SilviaProst.com y el responsable del sitio web ajeno.',
    ];

    return <PolicyPage title={title} content={content} />;
};

export default AvisoLegal;
