import { useState } from 'react';
import {
    Button,
    Box,
    Image,
    Divider,
    Grid,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Text,
} from '@chakra-ui/react';

// Components
import OrderForm from '../../components/form/orderForm';

// Styles
import './styles.scss';

const Information = ({ isOpen, onClose, paint }) => {
    const [showForm, setShowForm] = useState(false);

    const handleCloseModal = () => {
        setShowForm(false);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered size={'6xl'}>
            <ModalOverlay backdropFilter="brightness(50%)" />
            <ModalContent className="modalpainting">
                <ModalCloseButton className="closebutton" />
                <ModalBody className="body">
                    <Box className="left">
                        <Image className="image" src={paint.imagehd} />
                    </Box>
                    <Box className="right">
                        {showForm ? (
                            <Box
                                className='right-order'
                                overflowY={'scroll'}
                                style={{
                                    scrollbarWidth: 'thin',
                                    scrollbarColor: '#724162',
                                    WebkitOverflowScrolling: 'touch', // Allow smooth slide in iOS
                                }}
                            >
                                <Text className="ordertitle">
                                    Una vez que recibamos tu solicitud, nuestro equipo se pondrá en
                                    contacto contigo en un plazo de 5 días para confirmar los
                                    detalles de la transacción y coordinar el envío de la pintura.
                                </Text>
                                <Divider marginY={'15px'} color={'#dadddd'} />
                                <OrderForm item={paint} closeForm={setShowForm} type={'paint'} />
                            </Box>
                        ) : (
                            <Grid className="information">
                                <Text className="title">{paint.title}</Text>
                                <Divider marginTop={{ sm: 0, md: '7px' }} color={'#dadddd'} width={'80%'} />
                                <Grid className="items">
                                    <Text className="item">
                                        Materiales:{' '}
                                        <Box className="text" as="span">
                                            {paint.materials}
                                        </Box>
                                    </Text>
                                    <Text className="item">
                                        Tamaño:{' '}
                                        <Box className="text" as="span">
                                            {paint.size}
                                        </Box>
                                    </Text>
                                    <Text className="item">
                                        Año:{' '}
                                        <Box className="text" as="span">
                                            {paint.year}
                                        </Box>
                                    </Text>
                                    {paint.onSale && (
                                        <Text className="item">
                                            Estado:{' '}
                                            <Box className="available" as="span">
                                                Disponible
                                            </Box>
                                        </Text>
                                    )}
                                    {!paint.onSale && (
                                        <Text className="item">
                                            Estado:{' '}
                                            <Box className="unavailable" as="span">
                                                No está a la venta
                                            </Box>
                                        </Text>
                                    )}
                                    {paint.onSale && (
                                        <Text className="item">
                                            Precio:{' '}
                                            <Box className="text" as="span">
                                                {paint.price}
                                            </Box>
                                        </Text>
                                    )}
                                </Grid>
                                {!paint.encuadrado && (
                                    <Grid className="unframed">
                                        <Flex>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 48 48"
                                            >
                                                <path
                                                    fill="#2196f3"
                                                    d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                                                ></path>
                                                <path
                                                    fill="#fff"
                                                    d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"
                                                ></path>
                                            </svg>
                                            &nbsp;
                                            <Text className="info">Lámina sin Encuadrar</Text>
                                        </Flex>
                                    </Grid>
                                )}
                                {paint.onSale && (
                                    <Button className="button" onClick={() => setShowForm(true)}>
                                        Solicitar
                                    </Button>
                                )}
                            </Grid>
                        )}
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default Information;
