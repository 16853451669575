import { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';

// Components
import CarouselBooks from '../../components/carousel/books';
import Footer from '../../components/footer/footer';
import LaEscuelaQueEstafaBanner from '../../components/banner/laEscuelaQueEstafa/laEscuelaQueEstafa';
import ContactBanner from '../../components/banner/contact/contact';
import PaintingsBanner from '../../components/banner/paintings/paintings';

// Styles
import './styles.scss';

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const elementoDestinoRef = useRef(null);

    // All in english. I need to be consistent in the whole code
    const desplazar = () => {
        if (elementoDestinoRef.current) {
            const desplazamiento = elementoDestinoRef.current.offsetTop;

            window.scroll({
                top: desplazamiento,
                behavior: 'smooth'
            });
        }
    };

    return (
        <Box paddingTop={'90px'} className='home'>
            <LaEscuelaQueEstafaBanner desplazar={desplazar} />
            <div ref={elementoDestinoRef} id="elementoDestino">
                <CarouselBooks />
            </div>
            <PaintingsBanner color={'#724162'} />
            <Box marginBottom={'100px'}/>
            <ContactBanner />
            <Footer />
        </Box>
    );
};

export default Home;
