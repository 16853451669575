import { useEffect } from 'react';
import { Box, Text, Grid, Image } from '@chakra-ui/react';

// Components
import ContactForm from '../../components/form/contactForm';
import Footer from '../../components/footer/footer';

// Resources
import airplane from './../../resources/paper-airplane.png';

// Styles
import './style.scss';

const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="contact">
                <Grid className="contact-grid">
                    <Text className="form-title">Formulario</Text>
                    <Text className="contact-title">CONTACTO</Text>
                    <Box className="form-box">
                        <ContactForm />
                    </Box>
                </Grid>
                <Box className="info-box">
                    <>
                        <Text className="info-text">
                            Desde este formulario podrás <br />
                            enviar un mensaje <br />a Silvia Prost.
                        </Text>
                        <Image className="info-image" src={airplane} />
                    </>
                </Box>
            </div>
            <Footer />
        </>
    );
};

export default Contact;
