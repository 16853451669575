import Lucio from './../resources/articles/lucio.jpg';
import Utelpa from './../resources/articles/utelpa.jpg';
import CronicaDeUnEspanto from './../resources/articles/cronica_de_un_espanto.png';
import Libertad from './../resources/articles/libertad.jpg';

const articles = [
    {
        title: 'Libertad de pensar, de decir y decidir',
        description: 'El artículo critica la intervención política de ciertos dirigentes sindicales en nombre de los docentes pampeanos, cuestionando su derecho a expresar opiniones personales y su capacidad para decidir por sí mismos. Además, critica la discrepancia entre la realidad educativa vivida y la retórica oficial, subrayando la necesidad de una representación sindical más inclusiva y respetuosa de la autonomía de los docentes. El artículo defiende el derecho fundamental a la libertad de pensamiento y expresión, llamando a un mayor respeto hacia la diversidad de opiniones dentro de la comunidad educativa',
        date: '13 de noviembre, 2015',
        image: Libertad,
        link: 'libertad-de-pensar-de-decir-y-decidir',
        minutes: '5',
        tags: ['dirigentes', 'Utelpa', 'libertad de pensar'],
        text: [
            'El día miércoles 11 de noviembre los docentes pampeanos amanecimos sorprendidos una vez más por nuestro gremio.  Según supimos a través de este diario, acompañada por dirigentes de CTERA que expresaron su “no a Macri”, Noemí Tejeda -de UTELPA- “advirtió” contra las políticas que aplicará Cambiemos si gana el próximo balotaje. Dejando de lado la habilidad precognitiva de la que se ufanan estos dirigentes gremialistas, que no pudieron sin embargo anunciar algunos de los muchos males que nos aportó el kirchnerismo, hay que decir que tales expresiones nos colocan a los docentes pampeanos en una posición de menores o ineptos que no tienen capacidad de decisión, en cuanto que necesitamos que dirigentes gremiales nos “adviertan” y nos digan qué debemos pensar o -en este caso- a quién debemos votar.',
            'Como  maestra y profesora nacida en esta querida provincia, en la que he vivido la mayor parte de mi vida, quiero manifestar públicamente mi desacuerdo con las expresiones vertidas por estos dirigentes.  Me dan la autoridad para hacerlo el ser integrante de UTELPA, vivir en una sociedad democrática y la dignidad de vivir como una persona, con libertad de pensar y de expresión.  Por eso, y a pesar de que lo que digo es opinión propia -no tengo autoridad para hablar en nombre de nadie- he de decir que vi muchos docentes molestos por la misma causa que yo.',
            'Hace años observamos consternados que la dirigencia de nuestro gremio realiza opciones y manifestaciones en debates políticos y educativos en nuestro nombre, sin consultarnos o tener en cuenta nuestras opiniones.  Así se hacen conferencias y declaraciones en nombre de UTELPA, en las que nos involucran a todos los afiliados quienes -mal que les pese a los dirigentes- somos personas libres y tenemos diversidad de opiniones y elecciones políticas; y poseemos también nuestras propias ideas.  Sería bueno que empiecen a considerarlo.',
            'En lo personal, me siento muy molesta en estos tiempos cuando como parte del colectivo docente se me incluye en descripciones que detallan las “bellezas y bondades” de la educación; la promovida a través del renombrado presupuesto del 6% del PBI” del kirchnerismo, frente al pretendido “deplorable desmembramiento” educativo de las políticas neoliberales de la Ley Federal que concluyeron con la carpa blanca. Esta descripción de una realidad paralela, aunque repetida una y mil veces como parte del “relato”, no describe mi pensamiento en absoluto.',
            'En lo que me atañe, veo hoy, a solo cinco años de la vigencia de la Ley Filmus en la provincia, una educación en franco declive, cuyo piso parece aún no haber llegado.  El educativo es un sistema en el cual -como en otros ámbitos- se desarrollan peroratas floridas que se bajan en documentos, videos, cursos obligatorios, discursos de autoridades y otras sutilezas, que nada tienen que ver con la realidad.  Lo que se dice y lo que se escribe dista mucho de lo que se ve, y se vive, y cree que con esto estarán de acuerdo muchos lectores, padres y docentes.',
            'Por lo antes dicho, la campaña de UTELPA contra el candidato de Cambiemos no me representa y creo -sin temor a equivocarme- que ocurre algo similar con muchos docentes pampeanos a los que invito a expresarse.  Considero inapropiado e indigno que se digan cosas en nombre de personas a las cuales no se ha consultado, realizando en este proceso una discriminación de los representados, cuyos reclamos por el particular son resueltos con los discursos vacuos de siempre.  El trato al que se somete a los afiliados se aleja así en la práctica de la teoría, que se pregona en el mismo ámbito educativo, donde se alardea de derechos humanos, libertad de pensamiento, consideración del sujeto, respeto por las diferencias, inclusión… bla, bla,bla…',
            ' Precisamente de eso hablamos. Del derecho a pensar y decir lo que se piensa, que es un derecho humano fundamental garantizado por nuestra Constitución, el cual yace violado una y otra vez cuando se usa la representación gremial para “hablar” en nombre de los docentes y de ese modo se los silencia para siempre.  Quiera Dios que los nuevos tiempos -gane Macri o Scioli- traigan más libertad y respeto hacia la libertad del otro, porque los valores no se pregonan ni se expresan con discursos y cantinelas, solo se viven o se niegan de manera irrefutable y definitiva con las acciones.',

        ],
    },
    {
        title: 'Crónica de un espanto',
        description: '"Crónica de un Espanto" aborda la frustración y agotamiento de los docentes ante las constantes exigencias y cambios en el sistema educativo. Prost critica la falta de consideración hacia los profesores, quienes se sienten relegados y alienados en un entorno burocrático y cambiante. Se cuestiona la responsabilidad de los gremios docentes en representar y defender los intereses de los maestros, así como la falta de consulta real en la implementación de nuevas leyes educativas. La crónica refleja el descontento y la sensación de impotencia de los docentes frente a una situación que perciben como injusta e incomprensible.',
        date: '18 de septiembre, 2006',
        image: CronicaDeUnEspanto,
        link: 'cronica-de-un-espanto',
        minutes: '3',
        tags: ['Utelpa', 'abuso de poder'],
        text: [
            'Parece que aún no basta la variedad cuidadosamente refinada de padeceres que nos tienen reservados a los docentes.  Agotados por la lucha contra una realidad que nos supera y arrasa con nuestros esfuerzos más encendidos. Metidos en una estructura añeja, burocrática y verticalista, desde la cual debemos hacer el milagro de producir transformaciones sociales -que se dicen- dependen de la escuela.  Movidos primero hacia acá, luego hacia allá; acomodados y reacomodados, obligados a dar examen una y otra vez para reconvertirnos en ejecutores de “la nueva ley” que paradójicamente cambia con una periodicidad indigerible.  Hartos de que no se nos tenga en cuenta, mudos ya porque no se nos permite “decir”, salvo para llenar unas grillas o argumentar sobre temas que ya se han resuelto en una oficina.  O como realizadores de planes y proyectos que se nos imponen desde el “hay que…”y nos alienan como personas pensantes.',
            'Ahora resulta que gracias a las expresiones de Noemí Tejeda, que fueron publicadas en La Arena del lunes, nos enteramos de que hemos sido los culpables.   Que debemos pagar (¿otra vez nosotros?) la “...obediencia irrestricta de los años noventa…”  La misma obediencia irrestricta que curiosamente los gremios docentes -que debieran representarnos- practican por estas horas, vaya uno a saber porqué.        La misma que seguramente volveremos a pagar nosotros cuando una nueva ley -que estaremos obligados a aprender de memoria-  se digna la última.   Acusarnos de “obediencia irrestricta” en estas horas de zozobra y dolor en que nos percatamos una vez más de que estamos a merced de designios y tratos cuyo origen nos es desconocido, es más de lo que se puede soportar.',
            'Yo le pregunto a la Sra. Tejeda: ¿en qué país vivía cuando se implementó la Ley Federal, cómo ella no paró lo que según su decir debimos parar nosotros?  Pero además pregunto: ¿por qué ahora que nos representa, no puede hacer que se nos escuche? Ahora que todavía no terminamos de sorprendernos de que los resultados de las consultas no tienen nada que ver con nuestra opinión, de modo que no sabemos de dónde salen.  Y no entremos a hacer detalles acerca de cómo se implementó la jornada de consulta, o del curioso documento que se elaboró para tal fin y la grilla correspondiente, todo preparado para que al término de su lectura uno no pudiese decir más que lo que debía decir.  Por qué como representante nuestro no cuestiona el hecho de pretender implementar una nueva ley sin un diagnóstico serio de la realidad educativa del país, que claro, habría que realizarlo con un cuidado y un tiempo que no se condicen con el apresuramiento electoral.',
            'Parece ser que todo está aún no era suficiente.  Ahora los docentes venimos a agregar una cuota más a nuestra náusea colectiva.  Y no se equivoquen quienes se crean con autoridad para hablar de nosotros.  Más que la obediencia, nos une un patético espanto.',
        ],
        additionalInfo: 'La carta de la autora fue publicada -con pseudónimo- en el Diario de La Pampa, luego de salir la nota de Noemí Tejeda de UTELPA, el 18 de septiembre del año 2006 titulada “La transferencia será traumática”.  La respuesta no se pudo publicar en el mismo diario oficial de la Sra. Tejeda.',
    },
    {
        title: '¿Qué derechos defiende UTELPA?',
        description: 'Silvia Prost llama la atención de los docentes pampeanos, sobre todo de las mujeres, señalando el abuso de poder al que son sometidas cuando UTELPA habla por ellas, rechazando de plano al nuevo gobierno que no es de su militancia y adhiriendo a un paro convocado por el gremialismo más cuestionado.  Los gremios no deberían tener filiación política porque sus afiliados lo son debido a su profesión y en lo personal tienen ideas diferentes, es decir, simpatizan con diferentes partidos.',
        date: '19 de enero, 2024',
        image: Utelpa,
        link: 'que-derechos-defiende-utelpa',
        minutes: '5',
        tags: ['Utelpa', 'paro', 'abuso de poder'],
        text: [
            'Hace exactamente diecisiete años y cuatro meses Noemí Tejeda, la entonces secretaria gremial de UTELPA, el gremio docente pampeano, vaticinaba que los docentes “pagarían caro” el haber cumplido de manera “irrestricta” la Ley Federal de los 90. Como si hubiera sido posible no hacerlo.  Entonces, quienes como yo transitábamos la mitad de la carrera y veníamos hablando de crisis desde los 80, además de jóvenes, éramos entusiastas y creíamos que había mucho por reformar en la educación. ¿Qué hubiera pasado si aquellos cambios no se hubieran interrumpido? ¿Qué educación tendríamos hoy?',
            'Pero dejando el reino de lo contrafáctico y volviendo al inicio, en algo no se equivocó la gremialista: fue muy costoso para todos, no solo para los docentes. Aunque no por la ley menemista, sino por lo que vino después.   El fracaso del gobierno de Menem y la crisis de 2001 nos trajo algo mucho peor.   Luego del intervalo de Duhalde, ingresamos sin aviso en las dos décadas más negras de nuestra vida democrática: la hegemonía del kirchnerato, que transformó la cultura en relato militante, la verdad en consigna votada, e instaló a lo largo y ancho de la nación la más profunda grieta ideológica y moral que haya dividido jamás a los argentinos. De la que aún no salimos.',
            'Eso no fue todo, el kirchnerismo también se ocupó cuidadosamente de destruir el ya débil sistema de educación pública, desterrando para siempre valores tradicionales como la idoneidad, el trabajo, el mérito, el esfuerzo, el estudio y la veracidad.  Los cargos de importancia -incluso en educación- se cubrieron con frecuencia, con ineptos que exhibieron como únicos dotes su adhesión irreflexiva a la ideología imperante.  La  pseudo realidad se transformó en cliché repetido hasta el hartazgo.  Paralelamente a la destrucción de la cultura nacional se vació la educación de principios rectores, de normas y de contenidos; y se cubrió todo con panfletos, consignas mentirosas, pañuelos verdes, adoctrinamiento y simulacros de defensa de derechos. Hasta la Ley Nacional terminó siendo un relato que según juristas destacados carece de fuerza normativa por la vaguedad de sus términos.',
            'Y si no creen que el mundo kirchnerista es una obra de teatro déjenme dar solo un ejemplo.  Se gastaron millones y millones en crear estructuras para defender a la mujer contra la violencia, replicando aquella consigna “ni una menos” de manera repetida e incesante; sin embargo ¿conoce alguno de los lectores la cantidad exacta de mujeres “desaparecidas” como María Cash (2011) mientras transcurría el paraíso de derechos kirchneristas?  Nadie tiene esos datos, nunca se contabilizaron, por supuesto. Tampoco se investigó su desaparición.  Ni se contaron los hombres desaparecidos, que también son muchos.  Este solo hecho de la realidad demuestra la puesta en escena dantesca y mentirosa que construyó el matrimonio Kirchner para mantenerse como protagonistas exclusivos, ocultar sus operaciones económicas y conservar su poder, tal vez con pretensiones hereditarias. Ojalá este peligro haya sido conjurado esta vez.',
            'Pero resulta que ahora, enero de 2024, el gremio docente pampeano -después de tantos años-  tiene algo para decir.  A menos de dos meses de asumido el nuevo gobierno ya se opone de plano a todo el paquete de propuestas del Presidente Milei anunciando la pérdida de derechos docentes y la afectación del derecho a la educación -entre otros males- “dícese” que garantizados hasta hace un mes por el último gobierno kirchnerista.    Me pregunto, nos preguntamos: ¿dónde estuvieron estos veinte años de abuso populista? ¿Dónde estaban cuando vaciaban de contenidos las materias, cuando mandaban material adoctrinador a las escuelas, cuando convirtieron los certificados de estudio en papeles sin valor? ¿Dónde cuando comenzaron a egresar alumnos que no leen comprensivamente ni resuelven problemas matemáticos simples? ¿Dónde cuando pasaron por encima de la dignidad de los docentes una y otra vez, cuando nos obligaron a callarnos? ¿Dónde cuando nos enfermamos por tener que sobrevivir en un sistema educativo farsante? ¿Dónde estaban?',
            'Probablemente recibiendo algún beneficio, porque como puede verse,  este gremio como otros parece habitar dentro de la realidad paralela proyectada por CFK y sus laderos y beneficiarios.  Se compró completa la película de que éramos felices hasta el 10 de diciembre, gozamos de paz, seguridad, educación y demás derechos y bienes divinos.  Bueno, el gremio entero no, los que conducen el mismo, porque a los afiliados que lo sostenemos económicamente nunca nos preguntaron qué opinamos. Porque el gremio milita un partido político - y esta sí que es obediencia irrestricta- aunque eso esté prohibido.  Un gremio no puede adherir a un partido político ya que las personas se afilian a él porque desempeñan un determinado trabajo, no porque simpatizan con  un partido.  De modo que “obligar” a todos a “militar” las mismas ideas políticas es un tipo de abuso.',
            'Es de suponer que los más de 6000 afiliados de UTELPA -en su mayoría mujeres- deben tener distintos pensamientos como estatura, color de cabello, nombre, personalidad, etc.; sin embargo el gremio que dice defenderlas a ellas y a sus compañeros varones, les impide expresarse autónomamente.  Pero no solo eso, también habla por ellas en el más evidente acto de abuso de poder al que se puede someter al colectivo femenino.  UTELPA expresa en nombre de  todos los docentes -la mayoría mujeres- su rechazo a la nueva gestión,  anunciando ya -por supuesto- su adhesión al paro convocado por impresentables como Moyano y Daer.  Un paro claramente político promovido por las oscuras fuerzas gremiales kirchnerperonistas (CGT, CTA), que no quieren renunciar a sus millonarias cajas ni a su poder, y que cuando no son gobierno hacen operaciones para derribarlo, porque los derechos de los ciudadanos se los pasan por el olvido.',
            '¿De verdad piensan que todos los docentes pampeanos apoyan esta dinámica autodestructiva del país y de su cultura? ¿De verdad hay quienes todavía creen que los dirigentes gremiales como Baradel defienden los derechos de sus afiliados?  ¿Todavía no se dieron cuenta de que solo luchan por ellos mismos y sus intereses y les importan un cuerno los afiliados y el país?  Para finalizar voy a dirigirme a mis colegas femeninas: ¡¡despertad docentes pampeanas!! ¡Es momento de decir lo que pensamos y hablar por nuestra propia voz!  ¡Es hora de democratizar y sanear el gremio para hablar por nosotras mismas! ¡La libertad no se solicita, se ejerce!'
        ],
    },
    {
        title: 'Sobre violencias y géneros. Reflexiones derivadas del caso Lucio en La Pampa',
        description: 'A partir del caso del niño asesinado en La Pampa por la madre y su pareja, el artículo reflexiona sobre la existencia de una cierta ceguera institucional y social en el caso de las violencias no convencionales. Parece muy difícil hacer visibles a las víctimas cuando no se hallan comprendidas en las leyes y protocolos que rezan sobre la violencia de género y otros colectivos minoritarios.',
        date: '10 de diciembre, 2021',
        image: Lucio,
        link: 'sobre-violencias-y-generos-reflexiones-derivadas-del-caso-lucio-en-la-pampa',
        minutes: '5',
        tags: ['Lucio Dupuy', 'otras violencias'],
        text: [
            'Que la violencia es un hecho cotidiano, aunque lamentable, es evidente para todos. En general las estadísticas muestran los crecientes temores de la sociedad a la violencia asociada a la delincuencia, sin embargo, existe un proceso mucho menos visible de deterioro del entramado social que tiene que ver con el incremento de dinámicas violentas en el seno de las familias. Al respecto, quienes se dedican al estudio del tema, explican que en el caso de los niños y las mujeres que sufren maltrato, el mismo es provocado en general por agresores que pertenecen al núcleo familiar o de amistades estrechas. Y a esto estamos habituándonos merced a la divulgación cada vez más frecuente de los casos, a la sanción de leyes de protección contra la violencia de género y a la acción de organismos oficiales abocados a defender a la mujer y a las minorías frente a las diversas violencias de que son objeto. Destacamos en este punto la importancia de visibilizar las diferentes manifestaciones de la violencia y sus efectos, habilitando de este modo a que muchas víctimas se animen a denunciar a sus victimarios independientemente de sus ascendientes e influencias. Basten como ejemplos dos resonantes casos: el de Juan Darthés iniciado por Thelma Fardin y el de Alperovich denunciado por su sobrina, cuyas causas transitan ahora en la justicia, como debe ser. Abogamos por una aceleración en estos procesos. ',
            'Pero hay otro punto al que me quiero referir y es el que tiene que ver con la violencia ejercida desde una minoría, es decir, qué sucede cuando la violencia no es de género, ni se origina en la estructura paternalista y machista de la sociedad. Y qué pasa cuando además se trata de una violencia que no quiere ser mirada, que es repulsiva al sentido común y que por eso se instala en ese agujero negro donde el ojo queda a oscuras y nada se ve. Porque seguimos hablando de violencias, a no ser que postulemos que la violencia ejercida por las mujeres no es violencia, a no ser que entendamos que una madre no puede de ningún modo ejercer violencia sobre el hijo o la criatura que está a su cargo. Me parece que no podríamos calificar de otro modo el abuso de una madre sobre el hijo, independientemente de que ese abuso sea físico, psicológico, sexual o emocional; creo que todos coincidimos en que también en este caso se trata de violencia, aunque no encuadre dentro de las violencias que estamos habituados a reconocer. ',
            'Y es que muchas veces a lo largo de la historia, cuando se ha resaltado algo para destacarlo de la totalidad, para abocarse a ello con detalle y empeño, se han dejado de lado otros fenómenos más importantes o iguales que el atendido. O bien, enfatizando el caso a defender se ha olvidado tener la visión del conjunto, es lo que se conoce comúnmente como tapar el bosque con el árbol. En este caso particular hemos de recordar que, si bien la violencia de las madres contra los hijos no es poco frecuente, también ha sido involuntariamente invisibilizada al realzar los padecimientos del colectivo femenino, interpretándose que la violencia femínea no es tan violenta o tan reprochable, por ser tal vez la consecuencia de una agresión anterior perpetrada por el machismo autoritario. Argumento absurdo si los hay, porque con igual lógica también se podría imputar a la madre de un victimario la causa de su violencia masculina. Hemos de aceptar entonces que, tratándose de violencias, siempre hablamos de daños, y estos se desatan para toda la sociedad, especialmente para la víctima y su entorno, sea esta mujer, hombre, no binario, morocho, rubio, castaño, argentino, extranjero, o perteneciente a cualquier otra categorización. ',
            'Siguiendo este análisis es como puede entenderse que en una provincia como la de La Pampa, donde el número de habitantes no llega a cuatrocientos mil, haya ocurrido el lento pero inexorable asesinato de un niño de cinco años en manos de la desquiciada madre y su pareja, frente a las indiferentes narices de tantos organismos específicamente montados para poner por encima de todo el “interés superior del niño, niña y adolescente”. La ceguera de los aparatos se evidenció además en el hecho de que el caso no se produjo en el interior incivilizado del monte como podría suponerse, sino en la misma capital de la provincia, en donde residen todas las oficinas de los funcionarios que cobran sueldos del erario público dícese que para organizar mejor la vida en comunidad. Requerirá un estudio más profundo determinar por qué en este caso no funcionaron las estructuras de defensa del niño, quien según se sabe concurría a diario a una escuela de Nivel Inicial, la justicia había sido alertada del peligro que significaba la madre. Además, había ingresado por traumatismos varios con una frecuencia alarmante a centros médicos de la ciudad, sin embargo, ningún funcionario público creyó conveniente dedicar su atención al tema, no hubo alertas desde educación, ni desde seguridad, ni desde salud. ¿Qué pasó? Al margen de la ineptitud que pueda caberle a algún funcionario. ¿Qué fue lo que sucedió que ninguno denunció el maltrato que recibía este pequeño? ',
            'Pero eso no es todo, resulta por demás sorprendente que la familia paterna del niño hubiera manifestado a los jueces su preocupación por el tema, un año antes. Esto lleva a pensar que hubo una inclinación en la justicia a pensar que por el solo hecho de ser mujer, la madre de Lucio estaba más preparada para cuidar de él; de nuevo el bosque tapando el árbol. O habrá inclinado la balanza hacia ella el hecho de que se tratara de una familia conformada por una pareja lesbiana y por lo tanto perteneciente a una minoría, usualmente víctima. Pero además ¿Por qué no se atendieron los reclamos de la familia paterna del niño? Es curioso que los jueces no interpretaran el impedimento de ver al padre y a los abuelos paternos como una acción extremadamente violenta contra el niño. Ni hablar del descuido de los derechos del padre, que jamás hubiera podido hacerlos visibles si el niño hubiera sobrevivido, estaba visto que si Lucio resistía aún abusado y apaleado, no habría existido modo de demostrar que su padre tenía razón. Convivimos con una justicia ciega, o tal vez vivimos en una sociedad en donde creemos que los padres por el solo hecho de ser hombres no tienen derecho a ver a sus hijos y/o son más mentirosos que las mujeres. Pero parece mucho más serio que eso, hemos perdido quizá un poco de sensibilidad por no decir de humanidad, frente al padecimiento ajeno. Hace años que hay hombres que reclaman ver a sus hijos, lo que tienen impedido por el solo hecho de ser varones; y otros que recelan de las mujeres porque -quien sabe- una simple denuncia falsa en las redes y no se podrá probar jamás que no se es acosador. ',
            'Concluyendo, se trata tal vez de volver a mirar con atención, de deconstruir las lógicas a las que venimos adhiriendo tácitamente, las que afirman dogmáticamente que las madres aman a los hijos, que los hombres son más violentos que las mujeres o que solo las minorías están privadas de sus derechos; entre otras. Se trata a lo mejor de desarrollar una real sensibilidad frente a las violencias, denunciándolas y poniéndose cada vez del lado de las víctimas, con independencia de quiénes son, del tipo de sexualidad que detentan, de las ideas que manifiestan y de las características que tienen. Solo de ese modo lograremos conformar una sociedad un poco menos injusta en donde no sea posible otro niño conviviendo con su asesina, abandonado a su suerte. '
        ],
    },
]

export default articles;
