import React from 'react';
import PolicyPage from './policyPage';

const CookiesPolicy = () => {
    const title = 'Política de Cookies';
    const content = [
        '¿Qué son las cookies?',
        'Las cookies son pequeños archivos de texto que se descargan en tu dispositivo cuando visitas un sitio web. Estos archivos permiten que el sitio web recuerde tus preferencias y ajustes, así como tu actividad en el sitio web, lo que permite una experiencia de usuario más personalizada.',
        '¿Qué tipo de cookies utilizamos?',
        'Cookies de análisis: Estas cookies recopilan información sobre cómo los usuarios utilizan el sitio web, qué páginas visitan con más frecuencia y si reciben mensajes de error. La información recopilada se utiliza para mejorar la experiencia del usuario y para optimizar el rendimiento del sitio web.',
        '¿Cómo puedes controlar las cookies?',
        'El usuario puede controlar el uso de cookies en su dispositivo a través de la configuración del navegador web que esté utilizando. Al modificar la configuración de cookies, el usuario puede optar por aceptar todas las cookies, rechazar todas las cookies o recibir una notificación cuando se envía una cookie al dispositivo. Por favor, tenga en cuenta que al desactivar las cookies, es posible que algunas funciones del sitio web no funcionen correctamente o que no puedas acceder a ciertas áreas del sitio web.',
        '¿Cómo obtener más información?',
        'Si tienes alguna pregunta sobre nuestra política de cookies, por favor contáctanos a través de nuestro formulario de contacto.',
        '¿Cómo se actualiza la Política de Cookies?',
        'SilviaHProst se reserva el derecho de actualizar y modificar esta Política de Cookies en cualquier momento y sin previo aviso. Te recomendamos que revises esta página con regularidad para estar al tanto de cualquier cambio en la política de cookies.',
        'Última actualización: 12 de enero de 2024.',
    ];

    return <PolicyPage title={title} content={content} />;
};

export default CookiesPolicy;
