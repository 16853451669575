import React, {useEffect} from 'react';
import { Grid, Text } from '@chakra-ui/react';
import Footer from '../../components/footer/footer';

const PolicyPage = ({ title, content }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <Grid
                minH={'100vh'}
                paddingTop={'120px'}
                paddingBottom={'20px'}
                paddingX={{ base: '8%', md: '15%' }}
                gap={5}
                lineHeight={'1.8em'}
                fontFamily={'Quattrocento Sans'}
            >
                <Text
                    color={'#724162'}
                    fontSize={{ base: '2xl', md: '2.5em' }}
                    fontFamily={'Cinzel Decorative'}
                    fontWeight={'600'}
                    textAlign={'center'}
                >
                    {title}
                </Text>
                {content.map((paragraph, index) => (
                    <Text key={index} textAlign={'justify'}>
                        {paragraph}
                        <br />
                    </Text>
                ))}
            </Grid>
            <Footer />
        </>
    );
};

export default PolicyPage;
