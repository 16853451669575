import { useEffect, useState } from 'react';
import { Box, Button, Center, Divider, Flex, Grid, Image, ScaleFade, Text, useMediaQuery } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

// Components
import CarouselBooks from '../../components/carousel/books';
import Footer from '../../components/footer/footer';
import OrderForm from '../../components/form/orderForm';

// Resources
import books from '../../database/books';

// Styles
import './styles.scss';
import BookSkeleton from '../../components/skeleton/book';
import BackButton from '../../components/ui/back-button';

const Book = () => {

    const [loading, setLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const { id } = useParams();

    const [isTablet] = useMediaQuery('(min-width: 590px) and (max-width: 1100px)');
    const selectedBook = books.find((book) => book.link === id);

    useEffect(() => {
        setLoading(true);
        setShowForm(false);
        window.scrollTo(0, 0);

        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [id]);

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <>
            {loading ?
                <BookSkeleton />
                :
                <>
                    <BackButton link={'/libros'} />
                    <Flex className="book">
                        <Flex className="bookcontent">
                            <Image className="image" alt={'Me amalabas'} src={selectedBook.image} />
                            <Flex className="information">
                                <Box className="top">
                                    <Text className="title">{selectedBook.title}</Text>
                                    <Text className="subtitle">{selectedBook.subtitle}</Text>
                                    <Divider />
                                    <Text className="description">{selectedBook.description}</Text>
                                </Box>
                                <Grid className="caracteristics">
                                    <Text className="datasheet">Ficha técnica</Text>
                                    <Text className="item">
                                        Editorial:{' '}
                                        <Box className="item-text" as="span">
                                            {selectedBook.editorial}
                                        </Box>
                                    </Text>
                                    <Text className="item">
                                        Fecha de publicación:{' '}
                                        <Box className="item-text" as="span">
                                            {selectedBook.date}
                                        </Box>
                                    </Text>
                                    <Text className="item">
                                        Número de páginas:{' '}
                                        <Box className="item-text" as="span">
                                            {selectedBook.pages}
                                        </Box>
                                    </Text>
                                    {selectedBook.collection &&
                                        <Text className="item">
                                            Colección:{' '}
                                            <Box className="item-text" as="span">
                                                {selectedBook.collection}
                                            </Box>
                                        </Text>
                                    }
                                    <Text className="item">
                                        Edición:{' '}
                                        <Box className="item-text" as="span">
                                            {selectedBook.edition}
                                        </Box>
                                    </Text>
                                    {selectedBook.isbn &&
                                        <Text className="item">
                                            ISBN:{' '}
                                            <Box className="item-text" as="span">
                                                {selectedBook.isbn}
                                            </Box>
                                        </Text>
                                    }
                                </Grid>
                            </Flex>
                            <Grid className="salebox">
                                {showForm ? (
                                    <ScaleFade initialScale={0.9} in={showForm}>
                                        <Grid padding={'10px'}>
                                            <OrderForm
                                                item={selectedBook}
                                                closeForm={setShowForm}
                                                type={'book'}
                                            />
                                        </Grid>
                                    </ScaleFade>
                                ) : (
                                    <>
                                        <Box className='salebox-content'>
                                            {selectedBook.ebook && (
                                                <Box className='ebook'>
                                                    <Text className='format-title'>Kindle</Text>
                                                    <Text className="ebook-text">
                                                        <Flex>
                                                            Precio Kindle:{' '}
                                                            <Box
                                                                as="span"
                                                                color={'#b12704'}
                                                                display={'flex'}
                                                                paddingLeft={'3px'}
                                                            >
                                                                US${selectedBook.ebookPrice}&nbsp;&nbsp;
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="30"
                                                                    height="30"
                                                                    viewBox="0 0 48 48"
                                                                >
                                                                    <path
                                                                        fill="#FFB300"
                                                                        d="M39.6,39c-4.2,3.1-10.5,5-15.6,5c-7.3,0-13.8-2.9-18.8-7.4c-0.4-0.4,0-0.8,0.4-0.6c5.4,3.1,11.5,4.9,18.3,4.9c4.6,0,10.4-1,15.1-3C39.7,37.7,40.3,38.5,39.6,39z M41.1,36.9c-0.5-0.7-3.5-0.3-4.8-0.2c-0.4,0-0.5-0.3-0.1-0.6c2.3-1.7,6.2-1.2,6.6-0.6c0.4,0.6-0.1,4.5-2.3,6.3c-0.3,0.3-0.7,0.1-0.5-0.2C40.5,40.4,41.6,37.6,41.1,36.9z"
                                                                    ></path>
                                                                    <path
                                                                        fill="#37474F"
                                                                        d="M36.9,29.8c-1-1.3-2-2.4-2-4.9v-8.3c0-3.5,0-6.6-2.5-9c-2-1.9-5.3-2.6-7.9-2.6C19,5,14.2,7.2,13,13.4c-0.1,0.7,0.4,1,0.8,1.1l5.1,0.6c0.5,0,0.8-0.5,0.9-1c0.4-2.1,2.1-3.1,4.1-3.1c1.1,0,3.2,0.6,3.2,3v3c-3.2,0-6.6,0-9.4,1.2c-3.3,1.4-5.6,4.3-5.6,8.6c0,5.5,3.4,8.2,7.8,8.2c3.7,0,5.9-0.9,8.8-3.8c0.9,1.4,1.3,2.2,3,3.7c0.4,0.2,0.9,0.2,1.2-0.1l0,0c1-0.9,2.9-2.6,4-3.5C37.4,30.9,37.3,30.3,36.9,29.8z M27,22.1L27,22.1c0,2-0.1,6.9-5,6.9c-3,0-3-3-3-3c0-4.5,4.2-5,8-5V22.1z"
                                                                    ></path>
                                                                </svg>
                                                            </Box>
                                                        </Flex>
                                                        Vendido por:{' '}
                                                        <Box as="span" color={'#000000'}>
                                                            Amazon.com Services LLC
                                                        </Box>
                                                    </Text>
                                                    <Button
                                                        className="kindlebutton"
                                                        onClick={() =>
                                                            openInNewTab(selectedBook.amazonlink)
                                                        }
                                                    >
                                                        Comprar ahora
                                                    </Button>
                                                    <Divider marginY={'7px'} color={'#dadddd'} />
                                                </Box>
                                            )}
                                            {selectedBook.available ? (
                                                <>
                                                    <Flex className='printed'>
                                                        <Text className='format-title'>Formato impreso</Text>
                                                        <Text className="printed-text">
                                                            <Flex>
                                                                Precio:{' '}
                                                                <Box
                                                                    as="span"
                                                                    color={'#b12704'}
                                                                    display={'flex'}
                                                                    paddingLeft={'3px'}
                                                                >
                                                                    ${selectedBook.price}&nbsp;&nbsp;
                                                                </Box>
                                                            </Flex>
                                                        </Text>
                                                        {selectedBook.soldBy.length > 0 &&
                                                        <Box className='availableIn' as="span" color={'#000000'}>
                                                            {selectedBook.soldBy.map((item, index) => (
                                                                <>
                                                                    Disponible en: {item.title}
                                                                    <Center
                                                                        marginTop={'10px'}
                                                                    >
                                                                        <Button
                                                                            key={index}
                                                                            className="paperbutton"
                                                                            onClick={() => openInNewTab(item.url)}
                                                                        >
                                                                            Comprar
                                                                        </Button>
                                                                    </Center>
                                                                </>
                                                            ))}
                                                        </Box>}
                                                        {selectedBook.soldBy.length < 1 &&
                                                            <Button
                                                                className="paperbutton"
                                                                onClick={() => setShowForm(true)}
                                                            >
                                                                Solicitar
                                                            </Button>
                                                        }
                                                    </Flex>
                                                </>
                                            ) : (
                                                <>
                                                    {selectedBook.presale ?
                                                        <Grid className="presale">
                                                            <Text className='presaletext'>El libro "{selectedBook.title}" ya está disponible para reserva. Asegura tu copia anticipada hoy.</Text>
                                                            <Divider marginY={'12px'} color={'#dadddd'} />
                                                            <OrderForm
                                                                item={selectedBook}
                                                                closeForm={setShowForm}
                                                                type={'book'}
                                                                preorder={'true'}
                                                            />
                                                            <Divider marginY={'7px'} color={'#dadddd'} />
                                                        </Grid>
                                                        :

                                                        <Grid className="unavailable">
                                                            <Flex>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 48 48">
                                                                    <path fill="#616161" d="M34.6 28.1H38.6V45.1H34.6z" transform="rotate(-45.001 36.586 36.587)" />
                                                                    <path fill="#616161" d="M20 4A16 16 0 1 0 20 36A16 16 0 1 0 20 4Z" />
                                                                    <path fill="#37474F" d="M36.2 32.1H40.2V44.4H36.2z" transform="rotate(-45.001 38.24 38.24)" />
                                                                    <path fill="#64B5F6" d="M20 7A13 13 0 1 0 20 33A13 13 0 1 0 20 7Z" />
                                                                    <path fill="#BBDEFB" d="M26.9 14.2c-1.7-2-4.2-3.2-6.9-3.2s-5.2 1.2-6.9 3.2c-0.4 0.4-0.3 1.1 0.1 1.4 0.4 0.4 1.1 0.3 1.4-0.1C16 13.9 17.9 13 20 13s4 0.9 5.4 2.5c0.2 0.2 0.5 0.4 0.8 0.4c0.2 0 0.5-0.1 0.6-0.2C27.2 15.3 27.2 14.6 26.9 14.2z" />
                                                                </svg>
                                                                <Text className="title">No disponible</Text>
                                                            </Flex>
                                                            <Button className="unavailablebutton">
                                                                <Text className='unavailablebutton-text'>
                                                                    En este momento{' '}
                                                                    <b>{selectedBook.title}</b> <br />
                                                                    no se encuentra disponible.
                                                                </Text>
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </>
                                            )}
                                            {selectedBook.pseudonimo && (
                                                <Grid className="pseudonimo">
                                                    <Flex>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            x="0px"
                                                            y="0px"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 48 48"
                                                        >
                                                            <path
                                                                fill="#2196f3"
                                                                d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                                                            ></path>
                                                            <path
                                                                fill="#fff"
                                                                d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"
                                                            ></path>
                                                        </svg>
                                                        &nbsp;
                                                        <Text className="author">
                                                            {selectedBook.author}
                                                        </Text>
                                                    </Flex>
                                                    <Text className="pseudonimo-text">
                                                        El nombre que figura en este libro es un pseudónimo
                                                        utilizado por la autora para este trabajo en
                                                        particular.
                                                    </Text>
                                                </Grid>
                                            )}
                                        </Box>
                                    </>
                                )}
                            </Grid>
                        </Flex>
                    </Flex>
                </>
            }
            {isTablet && !loading && <Text className="descriptionMd">{selectedBook.description}</Text>}
            <CarouselBooks />
            <Footer />
        </>
    );
};

export default Book;
