import emailjs from '@emailjs/browser';

export const sendEmail = async (data, setLoading, template) => {
    setLoading(true)

    try {
        await emailjs.send(
            'service_fbui7tr',
            template,
            {
                from_name: `${data.fullname}`,
                to_name: '',
                message: data.message,
                reply_to: data.email,
            },
            'XVQAh9z8OME29ELQo',
        );
    } catch (error) {
        console.error('Error sending email:', error);
        throw error;
    } finally {
        setTimeout(function () {
            setLoading(false);
        }, 2000);
    }
};
