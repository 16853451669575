import { useEffect } from 'react';
import { Box, Flex, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from '@chakra-ui/react'

// Components
import Article from './article';
import Essay from './essay';
import Footer from '../../components/footer/footer';

// TODO: implement a database and remove this
// Data
import articles from '../../database/articles';
import essays from '../../database/essays';

// Styles
import './styles.scss';
import { SECONDARY_BACKGROUND_COLOR } from '../../colors';

const Blog = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Box paddingTop={'100px'} maxWidth={'1200px'} margin={'auto'} minHeight={'700px'}>
                <Tabs size='md' variant='enclosed'>
                    <TabList color={SECONDARY_BACKGROUND_COLOR}>
                        <Tab fontWeight={'bold'}>Educación</Tab>
                        <Tab fontWeight={'bold'}>Filosofía</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Flex className='blog'>
                                {articles.map((article, index) => (
                                    <div key={index}>
                                        <Article article={article} />
                                    </div>
                                ))}
                            </Flex>
                        </TabPanel>
                        <TabPanel heigth={'100%'}>
                            <Flex className='blog'>
                                {essays.map((essay, index) => (
                                    <div key={index}>
                                        <Essay essay={essay} />
                                    </div>
                                ))}
                            </Flex>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
            <Footer />
        </>
    )
}

export default Blog;
