import { useState } from 'react';
import {
    Button,
    Center,
    Spinner,
    Textarea,
    Input,
    Grid,
    FormControl,
    FormLabel,
    FormErrorMessage,
    ScaleFade,
    Text,
} from '@chakra-ui/react';

// Services
import { sendEmail } from '../../services/mailer/mail-service';
import { CONTACT_TEMPLATE } from '../../services/mailer/mail-templates';
import { validateContact } from '../../utils/form-helper';

// Styles
import './style.scss';

const ContactForm = () => {

    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [data, setData] = useState({
        fullname: '',
        email: '',
        message: '',
    });

    const [errors, setErrors] = useState({
        fullname: false,
        email: false,
        message: false,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!sent && validateContact(data, errors, setErrors)) {
            const updatedData = {
                ...data,
                message: `${data.message}\n\nEmail: ${data.email}` // TODO: Add the email in Emailjs and remove this
            };
            sendEmail(updatedData, setLoading, CONTACT_TEMPLATE);
            setSent(true);
        }
    };

    const handleChange = (field, value) => {
        setData({ ...data, [field]: value });
        setErrors({ ...errors, [field]: false });
    };

    return (
        <>
            <div className="contactform">
                {!loading && !sent &&
                    <>
                        <FormControl isRequired isInvalid={errors.fullname}>
                            <FormLabel className="label">Nombre completo</FormLabel>
                            <Input
                                className="input"
                                value={data.fullname}
                                name="fullname"
                                type="text"
                                onChange={(e) => handleChange('fullname', e.target.value)}
                            />
                            {errors.fullname && <FormErrorMessage>El nombre es inválido</FormErrorMessage>}
                        </FormControl>
                        <FormControl isRequired isInvalid={errors.email}>
                            <FormLabel className="label">Email</FormLabel>
                            <Input
                                className="input"
                                value={data.email}
                                name="email"
                                type="email"
                                onChange={(e) => handleChange('email', e.target.value)}
                            />
                            {errors.email && <FormErrorMessage>El email es inválido</FormErrorMessage>}
                        </FormControl>
                        <FormControl isRequired isInvalid={errors.message}>
                            <FormLabel className="label">Mensaje</FormLabel>
                            <Textarea
                                className="textarea"
                                value={data.message}
                                maxHeight={'300px'}
                                name="message"
                                onChange={(e) => handleChange('message', e.target.value)}
                            />
                            {errors.message && (
                                <FormErrorMessage>El mensaje no puede estar vacío</FormErrorMessage>
                            )}
                        </FormControl>
                    </>
                }
                {loading &&
                    <Center>
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='#724162'
                            size='xl'
                            marginY={'121px'}
                        />
                    </Center>
                }
                {!loading &&
                    sent &&
                    <ScaleFade initialScale={0.9} in={sent}>
                        <Grid className='contactform-sent' gap={5} paddingY={'84px'}>
                            <Text fontSize={'xl'}>¡Mensaje Enviado! ✉️</Text>
                            <Text>
                                Gracias por ponerte en contacto con Silvia. Tu mensaje ha sido recibido y ella se pondrá en contacto contigo tan pronto como sea posible. ¡Apreciamos tu interés y esperamos poder ayudarte!
                            </Text>
                        </Grid>
                    </ScaleFade>
                }
                <Button
                    className="button"
                    variant="primary"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                >
                    {loading ?
                        'Enviando...'
                        :
                        sent ?
                            <ScaleFade initialScale={0.9} in={sent}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
                                    <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                                    <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                                </svg>
                            </ScaleFade>
                            :
                            'Enviar mensaje'}
                </Button>
                <Text className={'recaptcha'}>
                    This site is protected by reCAPTCHA and the Google&nbsp;
                    <a href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp; and&nbsp;
                    <a href="https://policies.google.com/terms">Terms of Service</a>&nbsp; apply.
                </Text>
            </div>
        </>
    );
};

export default ContactForm;
