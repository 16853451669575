import { Box, Button, Divider, Flex, Grid, Image, ScaleFade, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

// Resources
import front from './../../../resources/books/la_escuela_que_estafa.png';
import dorso from './../../../resources/books/la_escuela_que_estafa_dorso.png';
import cloud1 from './../../../resources/cloud1.png';
import cloud2 from './../../../resources/cloud2.png';
import pipe from './../../../resources/pipe.png';

// Styles
import './styles.scss';

const LaEscuelaQueEstafaBanner = ({ simple, color, desplazar }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/libros/la-escuela-que-estafa');
    };

    return (
        <Box backgroundColor={simple ? '#5377ba' : color}>
            <Grid
                className="newBookBanner"
                clipPath={simple ? 'none' : 'polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%)'}
            >
                <Flex className="content">
                    <Box className="left">
                        <Image className="front" src={front} />
                        <Image className="dorso" src={dorso} />
                    </Box>
                    <Grid className="right">
                        <Image className='cloud1' src={cloud1} />
                        <Image className='cloud2' src={cloud2} />
                        <Image className='cloud3' src={cloud2} />
                        <Text className="author">Silvia Prost</Text>
                        <Text className="titlebook">La escuela que estafa</Text>
                        <Divider />
                        <Text className="subtitlebook">
                            Hitos de la debacle educativa argentina
                        </Text>
                        {!simple &&
                            <Button className="boton" onClick={() => desplazar()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
                                </svg>
                            </Button>}
                        <ScaleFade initialScale={0.9} in={true}>
                            <Button onClick={() => handleClick()} className='reserve'>Comprar ahora!</Button>
                        </ScaleFade>
                        <Image className='pipe' src={pipe} />
                    </Grid>
                </Flex>
            </Grid>
        </Box>
    );
};

export default LaEscuelaQueEstafaBanner;
