import { React, useEffect } from 'react';
import { Flex, Box, Image, ScaleFade, Text } from '@chakra-ui/react';

// Components
import Footer from '../../components/footer/footer';

// Resources
import silvia from '../../resources/silvia.png';

// Styles
import './styles.scss';

const Biography = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Flex className='biography'>
                <Box className='left'>
                    <Image
                        className={'image'}
                        src={silvia}
                    />
                </Box>
                <Box className='right'>
                    <ScaleFade initialScale={0.9} in={true}>
                        <Text className='author'> SILVIA H. PROST </Text>
                        <Text className='subtitle'> BIOGRAFÍA </Text>
                    </ScaleFade>
                    <Box className='text'>
                        <Text paddingBottom={'12px'}>
                            Nació en Guatraché, provincia de La Pampa, el 18 de octubre de 1966.
                        </Text>
                        <Text paddingBottom={'12px'}>
                            Con ocho o nueve años Silvia ya escribía los primeros poemas
                            dedicados a su madre, a partir de allí jamás dejó de escribir,
                            pasando por variadas temáticas y explorando distintos géneros como
                            cuento, ensayo, novela, poesía y texto dramático, entre otros.
                        </Text>
                        <Text paddingBottom={'12px'}>
                            De espíritu inquieto, a los 17 años comenzó a estudiar magisterio,
                            luego profesorado de Filosofía y más tarde Licenciatura en Ciencias
                            de la Educación; de este modo dedicó gran parte de su vida a lo que
                            identificó como su obsesión: la educación.
                        </Text>
                        <Text paddingBottom={'12px'}>
                            Fue docente durante más de 32 años repartiéndose entre los niveles
                            primario, secundario y superior no universitario, participando además en alguna
                            investigación sobre la temática educativa, desarrollada en la
                            Universidad de La Pampa, según consta en ponencias presentadas y
                            trabajos colectivos publicados. Su interés por la educación y en
                            especial su preocupación por los problemas de la educación
                            argentina, la llevó a sumergirse en el ensayo, de lo que resultó su
                            primer libro “La escuela que enferma” (2006), publicado con el
                            pseudónimo C. Pérez Torres. Catorce años más tarde publicaría otro
                            ensayo, esta vez dedicado a la educación familiar: “Veinte cosas que
                            usted puede hacer para arruinar la vida de su hijo” (2020). Ambos
                            textos tuvieron buena recepción en los lectores. Otro de sus
                            intereses desde la adolescencia, la Filosofía, motivó la aparición
                            de su cuarto libro: “Ensayo sobre la racionalidad” (2017). Además,
                            publicó un híbrido denominado “Manual del Corrupto Argentino”
                            (2011), una especie de panfleto que describe costumbres
                            inescrupulosas muy arraigadas en la clase política.  En 2022 publicó
                            una compilación de poemas escritos a lo largo de su vida denominado
                            Me amalabas, que incluye tres escritos en glíglico cortazariano,
                            considerado por la autora un lenguaje especialmente expresivo para
                            decir poéticamente el amor.  Su próxima obra -que saldrá en marzo de
                            2024- es un incisivo ensayo que retoma la temática educativa: La escuela
                            que estafa, de editorial De los Cuatro Vientos.
                        </Text>
                    </Box>
                </Box>
            </Flex>
            <Footer />
        </>
    );
};

export default Biography;
