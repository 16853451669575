import { Box, Grid, HStack, Image, Tag, Text, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import silvia from './../../resources/silvia2.png';

const BlogTags = ({ tags }) => {
    return (
        <HStack spacing={2} marginTop={2}>
            {tags?.map((tag) => {
                return (
                    <Tag size={'md'} variant="solid" backgroundColor={'#724162'} key={tag}>
                        {tag}
                    </Tag>
                )
            })}
        </HStack>
    )
}

const Article = ({ article }) => {
    const navigate = useNavigate();

    const handleClick = (link) => {
        navigate(`/blog/${link}`);
    };

    return (
        <>
            <Box className='blog-item'>
                <Box
                    display="flex"
                    flex="1"
                    position="relative"
                    flexDirection={'column'}
                    justifyContent={'start'}
                >
                    <Box
                        width={{ base: '100%', sm: '85%' }}
                        zIndex="2"
                    >
                        <Box textDecoration="none" _hover={{ textDecoration: 'none' }}>
                            <Image
                                borderRadius="lg"
                                src={article?.image}
                                alt="some good alt text"
                                objectFit="contain"
                                onClick={() => handleClick(article?.link)}
                                cursor={'pointer'}
                                style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}
                                maxH={'420px'}
                                width={'100%'}
                                backgroundColor={'white'}

                            />
                        </Box>
                    </Box>
                    <Box zIndex="1" width="100%" position="absolute" height="100%">
                        <Box
                            bgGradient={useColorModeValue(
                                'radial(#5d3f53 1px, transparent 1px)',
                                'radial(#5d3f53 1px, transparent 1px)',
                            )}
                            backgroundSize="20px 20px"
                            opacity="0.4"
                            height="100%"
                        />
                    </Box>
                </Box>
                <Box className='item-content' flex="1">
                    <Grid>
                        <Text
                            className='item-title'
                            onClick={() => handleClick(article?.link)}
                            cursor={'pointer'}
                        >
                            {article?.title}
                        </Text>
                        <BlogTags tags={article?.tags} />
                        <Text className='item-text'>
                            {article?.description}
                        </Text>
                    </Grid>
                    <Box className='item-bottom-author'>
                        <HStack marginTop="2" spacing="5" display="flex" alignItems="center" width={'80%'}>
                            <Image
                                width={'60px'}
                                src={silvia}
                                alt={'Foto de silvia'}
                                objectFit={'cover'}
                                clipPath='polygon(0 0, 100% 0, 100% 80%, 0 80%)'
                            />
                            <Grid textAlign={'start'}>
                                <Text as={'b'}>Silvia H. Prost</Text>
                                <Text display={'flex'} fontSize={'14px'}>
                                    <Box paddingTop={1} paddingRight={1}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                        </svg>
                                    </Box>
                                    {article?.minutes} min.
                                </Text>
                                <Text className='article-date'>
                                    <Text>{article?.date}</Text>
                                </Text>
                            </Grid>
                        </HStack>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Article;
