import { useState } from 'react';
import {
    Button,
    Center,
    Input,
    FormControl,
    FormErrorMessage,
    ScaleFade,
    Spinner,
    Text,
    Grid,
} from '@chakra-ui/react';

// Services
import { sendEmail } from '../../services/mailer/mail-service';
import { CONTACT_TEMPLATE } from '../../services/mailer/mail-templates';

// Styles
import './style.scss';

// Utilities
import { MAIN_BACKGROUND_COLOR } from '../../colors';
import { validateContact } from '../../utils/form-helper';

const ArticleForm = () => {

    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [data, setData] = useState({
        fullname: '',
        email: '',
        message: 'Quiero recibir la notificacion de nuevos articulos!'
    });

    const [errors, setErrors] = useState({
        fullname: false,
        email: false,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!sent && validateContact(data, errors, setErrors)) {
            setLoading(true);
            const updatedData = {
                ...data,
                message: `${data.message}\n\nEmail: ${data.email}` // TODO: Add the email in Emailjs and remove this
            };

            sendEmail(updatedData, setLoading, CONTACT_TEMPLATE);
            setSent(true)
        }
    };

    const handleChange = (field, value) => {
        setData({ ...data, [field]: value });
        setErrors({ ...errors, [field]: false });
    };

    return (
        <Grid className="articleform" gap={'5'}>
            {!loading && !sent &&
                <>
                    <FormControl isRequired isInvalid={errors.fullname}>
                        <Input
                            placeholder='Nombre completo'
                            className="articleform-input"
                            value={data.fullname}
                            name="fullname"
                            type="text"
                            onChange={(e) => handleChange('fullname', e.target.value)}
                            _placeholder={{ color: '#303d4d' }}
                            backgroundColor={MAIN_BACKGROUND_COLOR}
                        />
                        {errors.fullname && <FormErrorMessage>El nombre es inválido</FormErrorMessage>}
                    </FormControl>
                    <FormControl isRequired isInvalid={errors.email}>
                        <Input
                            placeholder='Correo electrónico'
                            className="articleform-input"
                            value={data.email}
                            name="email"
                            type="email"
                            onChange={(e) => handleChange('email', e.target.value)}
                            _placeholder={{ color: '#303d4d' }}
                            backgroundColor={MAIN_BACKGROUND_COLOR}
                        />
                        {errors.email && <FormErrorMessage>El email es inválido</FormErrorMessage>}
                    </FormControl>
                </>
            }
            {loading &&
                <Center>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='#724162'
                        size='lg'
                        marginY={'121px'}
                    />
                </Center>
            }
            {!loading &&
                sent &&
                <ScaleFade initialScale={0.9} in={sent}>
                    <Grid className='contactform-sent' gap={5} paddingY={'84px'}>
                        <Text fontSize={'xl'}>¡Inscripción Exitosa! ✉️</Text>
                        <Text>
                            Gracias por inscribirte al blog de Silvia. Te notificaremos cuando haya nuevos artículos.
                        </Text>
                    </Grid>
                </ScaleFade>
            }
            <Button
                backgroundColor={'#303d4d'}
                color={'white'}
                variant="primary"
                type="submit"
                onClick={(e) => handleSubmit(e)}
            >
                {loading ?
                    'Incribiendo...'
                    :
                    sent ?
                        <ScaleFade initialScale={0.9} in={sent}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                            </svg>
                        </ScaleFade>
                        :
                        'Inscribirme!'}
            </Button>
        </Grid>
    );
};

export default ArticleForm;
